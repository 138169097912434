import { fetchAuthSession } from "aws-amplify/auth";
import { API_SERVER } from "../config/constants";

export const createCampaign = async (campaign) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/campaigns`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
      body: JSON.stringify(campaign),
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to createCampaign:", error);
    throw error;
  }
};

export const fetchCampaign = async (campaignId) => {
  const session = await fetchAuthSession();
  const response = await fetch(`${API_SERVER}/campaigns/${campaignId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${session.tokens.idToken.toString()}`,
    },
  });
  const responseData = await response.json();
  if (!response.ok)
    throw new Error(responseData.error || "Network response was not ok");
  return responseData;
};

export const fetchCampaigns = async () => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/campaigns`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      throw new Error(responseData.error || "Network response was not ok");
    }

    return responseData;
  } catch (error) {
    console.error("Error Fetching campaigns:", error);
    throw error;
  }
};

export const updateCampaign = async (campaignId, postSocialNetwork, socialAccountId) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/campaigns/${campaignId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
      body: JSON.stringify({
        post_social_network: postSocialNetwork,
        social_account_id: socialAccountId,
      }),
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to updateCampaign:", error);
    throw error;
  }
};

export const deleteCampaign = async (campaignid) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/delete/${campaignid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to deleteCampaign:", error);
    throw error;
  }
};

export const publishCampaignPost = async (
  campaignId,
  postId,
  showReferences
) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/campaigns/${campaignId}/posts/${postId}/publish`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify({ include_references: showReferences }),
      }
    );
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.message || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to publishCampaignPost:", error);
    throw error;
  }
};

export const updateCampaignPost = async (campaignId, postId, post) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/campaigns/${campaignId}/posts/${postId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify(post),
      }
    );
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to updateCampaignPost:", error);
    throw new Error(error);
  }
};

export const fetchMultiCampaign = async (multiCampaignId) => {
  const session = await fetchAuthSession();
  const response = await fetch(
    `${API_SERVER}/multicampaigns/${multiCampaignId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
    }
  );
  const responseData = await response.json();
  if (!response.ok)
    throw new Error(responseData.error || "Network response was not ok");
  return responseData;
};

export const createMultiCampaign = async (campaign) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/multicampaigns`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
      body: JSON.stringify(campaign),
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to createMultiCampaign:", error);
    throw error;
  }
};

export const processImage = async (
  campaignId,
  prompt,
  seed,
  steps,
  useHtmlTemplate
) => {
  try {
    const session = await fetchAuthSession();
    const baseUrl = `${API_SERVER}/campaigns/${campaignId}/image`;
    let url;
    url = useHtmlTemplate ? `${baseUrl}/html_process` : `${baseUrl}/process`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
      body: JSON.stringify({
        prompt: prompt,
        seed: seed,
        steps: parseInt(steps),
      }),
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to processImage:", error);
    throw error;
  }
};

export const enhanceImage = async (campaignId) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/campaigns/${campaignId}/image/enhance`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify({}),
      }
    );
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to enhanceImage:", error);
    throw error;
  }
};
