export const stateMap = {
    'PUBLISHED': {
      'title': 'Published',
      'classNameOuter': 'bg-green-100',
      'classNameInner': 'text-green-500',
    },
    'DRAFT': {
      'title': 'Draft',
      'classNameOuter': 'bg-yellow-100',
      'classNameInner': 'text-yellow-500',
    },
    'ERROR': {
      'title': 'ERROR',
      'classNameOuter': 'bg-red-100',
      'classNameInner': 'text-red-500',
    },
    'DELETED': {
      'title': 'DELETED',
      'classNameOuter': 'bg-red-100',
      'classNameInner': 'text-red-500',
    }
};