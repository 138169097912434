import React, { useState, useEffect } from "react";
import * as NotificationService from "../../utils/notificationService";
import { FaTrash } from "react-icons/fa";
import Button from "../../components/common/Button";
import { ReactComponent as UploadIcon } from "../../assets/upload_files_icon.svg";
import { ReactComponent as PDFIcon } from "../../assets/pdf_icon.svg";
import { ReactComponent as DocxIcon } from "../../assets/docx_icon.svg";

const FileUploader = ({
  maxFiles = 10,
  maxFileSizeMB = 10,
  onFilesChange,
  initialFiles = [],
  readonly = false,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [initialFilenames, setInitialFilenames] = useState([]);

  useEffect(() => {
    const areFilenamesStrings = initialFiles.every((file) => typeof file === "string");
    if (areFilenamesStrings) {
      setInitialFilenames(initialFiles);
    } else {
      setSelectedFiles(initialFiles);
    }
  }, [initialFiles]);

  const handleFileSelection = (event) => {
    const files = Array.from(event.target.files);
    handleValidFiles(files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = Array.from(e.dataTransfer.files);
    handleValidFiles(files);
  };

  const handleValidFiles = (files) => {
    const validFiles = files.filter((file) => {
      const isValidType =
        file.type === "application/pdf" ||
        file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type === "application/msword";
      const isValidSize = file.size <= maxFileSizeMB * 1024 * 1024;
      return isValidType && isValidSize;
    });

    if (validFiles.length + selectedFiles.length > maxFiles) {
      NotificationService.notifyError(
        `You can only upload a maximum of ${maxFiles} files.`
      );
    } else {
      const updatedFiles = [...selectedFiles, ...validFiles].slice(0, maxFiles);
      setSelectedFiles(updatedFiles);
      onFilesChange(updatedFiles);
    }
  };

  const handleRemoveFile = (index, isFilename = false) => {
    if (isFilename) {
      const updatedFilenames = initialFilenames.filter((_, i) => i !== index);
      setInitialFilenames(updatedFilenames);
      onFilesChange(updatedFilenames);
    } else {
      const updatedFiles = selectedFiles.filter((_, i) => i !== index);
      setSelectedFiles(updatedFiles);
      onFilesChange(updatedFiles);
    }
  };

  const getIconForFile = (fileNameOrObject) => {
    const fileName = typeof fileNameOrObject === "string" ? fileNameOrObject : fileNameOrObject.name;
    if (fileName.endsWith(".pdf")) {
      return <PDFIcon />;
    } else if (fileName.endsWith(".docx") || fileName.endsWith(".doc")) {
      return <DocxIcon />;
    }
    return null;
  };

  const isFileLimitReached = selectedFiles.length >= maxFiles;

  return (
    <div className="flex flex-col items-center justify-center w-full">
      {!readonly && (
        <div
          className={`border-2 border-dashed rounded-lg w-full p-6 text-center ${
            isDragging ? "border-blue-500 bg-blue-100" : "border-gray-300"
          } bg-[#F5F7FA] ${
            readonly ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
          }`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <div className="flex flex-col items-center space-y-4">
            <p className="text-gray-900 font-bold text-xl leading-8 tracking-wide">
              Upload Files
            </p>
            <UploadIcon />

            {!readonly && (
              <label htmlFor="fileInput" className="w-full flex justify-center">
                <input
                  id="fileInput"
                  type="file"
                  multiple
                  onChange={handleFileSelection}
                  accept=".pdf,.docx,.doc"
                  className="hidden"
                  disabled={isFileLimitReached}
                />
                <Button disabled={isFileLimitReached}>Upload Files</Button>
              </label>
            )}
          </div>
        </div>
      )}

      {isFileLimitReached && !readonly && (
        <p className="text-red-500 font-semibold mt-2">File limit reached</p>
      )}

      {!readonly && (
        <p className="py-4">
          Upload up to {maxFiles} files: DOC, DOCX, or PDF. Max 10MB per file.
        </p>
      )}

      {initialFilenames.length === 0 && selectedFiles.length === 0 ? (
        <p className="text-gray-500 mt-4">No files selected</p>
      ) : (
        <div className="mt-4 w-full space-y-2">
          <div className="w-full border-none bg-white text-xl tracking-wide leading-none">
            Custom Files
          </div>
          {initialFilenames.map((fileName, index) => (
            <div
              key={index}
              className="flex justify-between items-center p-2 border-b border-gray-200"
            >
              <div className="flex items-center">
                {getIconForFile(fileName)}
                <span className="ml-2 text-gray-800">{fileName}</span>
              </div>
              {!readonly && (
                <button
                  className="text-red-500 hover:text-red-700"
                  onClick={() => handleRemoveFile(index, true)}
                >
                  <FaTrash />
                </button>
              )}
            </div>
          ))}
          
          {selectedFiles.map((file, index) => (
            <div
              key={index}
              className="flex justify-between items-center p-2 border-b border-gray-200"
            >
              <div className="flex items-center">
                {getIconForFile(file)}
                <span className="ml-2 text-gray-800">{file.name}</span>
              </div>
              {!readonly && (
                <button
                  className="text-red-500 hover:text-red-700"
                  onClick={() => handleRemoveFile(index)}
                >
                  <FaTrash />
                </button>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FileUploader;