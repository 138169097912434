import React, { useState, useRef, useEffect } from "react";

const Dropdown = ({
  selectedOption,
  defaultTitle = "Select an option",
  options,
  onSelect,
  readOnly = false,
  labelExtractor = (option) => option.label || option,
  disabledExtractor = (option) => option.disabled || false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    if (!readOnly) {
      setIsOpen((prevState) => !prevState);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleSelect = (option) => {
    if (!readOnly && !disabledExtractor(option) && onSelect) {
      onSelect(option);
      setIsOpen(false);
    }
  };

  return (
    <div ref={dropdownRef} className="relative w-full">
      <button
        className="flex items-center justify-between px-5 py-4 w-full bg-white rounded-lg border border-solid border-slate-300 min-h-[60px] text-neutral-900 focus:outline-none"
        onClick={toggleDropdown}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
      >
        <span className="flex-1 text-left">
          {selectedOption ? labelExtractor(selectedOption) : defaultTitle}
        </span>
        <svg
          className={`w-5 h-5 ml-2 transition-transform transform ${
            isOpen ? "rotate-180" : "rotate-0"
          } text-gray-500`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      {isOpen && (
        <ul
          className="absolute z-10 mt-2 w-full bg-white rounded-lg shadow-lg border border-slate-300 max-h-60 overflow-auto"
          role="listbox"
        >
          {options.length > 0 ? (
            options.map((option, index) => (
              <li
                key={index}
                role="option"
                className={`flex items-center px-4 py-2 text-neutral-700 cursor-pointer hover:bg-gray-100 ${
                  disabledExtractor(option) ? "text-gray-400 cursor-not-allowed" : ""
                }`}
                onClick={() => handleSelect(option)}
              >
                {labelExtractor(option)}
              </li>
            ))
          ) : (
            <li className="px-4 py-2 text-neutral-500">No options available</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;