import React, { useState } from "react";
import Step1 from "../../assets/step1.svg";
import Step2 from "../../assets/step2.svg";
import Step2Upload from "../../assets/step_2_upload.svg";
import Step3 from "../../assets/step3.svg";
import Step3Upload from "../../assets/step_3_upload.svg";
import Button from "../../components/common/Button";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { validateInput } from "../../utils/validations";
import { processStringToArrayByCommaSplit } from "../../utils/common";
import { 
  createKnowledgeBase,
  getUploadPresignedUrls,
  uploadFileToS3
} from "../../services";
import * as NotificationService from "../../utils/notificationService";
import AddDataFilterButton from "./AddDataFilterButton";
import AddDataFilterTitle from "./AddDataFilterTitle";
import DataFilterForm from "./DataFilterForm";
import TitleWithProgres from "./TitleWithProgress";
import ExpandableListItem from "./ExpandableListItem";
import ActionButtonsGroup from "../common/buttons/ActionButtonsGrup";
import FileUploader from "./FileUploader";
import FilterType from "../../data/enums/filterType";
import DataSourceType from "../../data/enums/data_source_type_enum";

function KnowledgeBase() {
  const [isReviewing, setIsReviewing] = useState(false);
  const [creatingDataFilter, setCreatingDataFilter] = useState(false);
  const [creatingCustomData, setCreatingCustomData] = useState(false);
  const [dataSourceSelected, setDataSourceSelected] = useState("Select Data Source");
  const [dataFiltersAdded, setDataFiltersAdded] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [name, setFilterName] = useState("");
  const [keywords, setKeywords] = useState("");
  const [authors, setAuthors] = useState("");
  const [isSecondStep, setIsSecondStep] = useState(false);
  const [knowledgeBaseName, setKnowledgeBaseName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [errors, setErrors] = useState({ name: "Knowledge Base name is required." });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(FilterType.NO_SELECTED);

  const navigate = useNavigate();

  const handleDataCreated = () => {
    setDataFiltersAdded((prevDataList) => [
      ...prevDataList,
      {
        name,
        source: dataSourceSelected,
        keywords,
        authors,
        start_date: startDate,
        end_date: endDate,
        file_names: [],
      },
    ]);
    setFilterName("");
    setKeywords("");
    setAuthors("");
    setStartDate("");
    setEndDate("");
    setDataSourceSelected("Select Data Source");
    setCreatingDataFilter(false);
  };

  const handleSelect = (item) => {
    setDataSourceSelected(item);
  };

  const handleToggleExpand = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleCreateNew = () => {
    setIsSecondStep(true);
    if (selectedFilter === FilterType.CUSTOM_FILES) {
      setCreatingCustomData(true);
    }
  };

  const handleSave = async () => {
    const newKbCreated = await createKnowledgeBaseHandler();
    if (newKbCreated) {
      navigate("/mkt/knowledgeBase", {
        state: { newKbCreated },
      });
    }
  };

  const handleCancel = () => {
    navigate("/mkt/knowledgeBase");
  };

  const createKnowledgeBaseHandler = async () => {
    try {
      let dataFilters;
      let filesS3Urls = [];
      let fileNames = [];
      let kbPreId;
      
      if (selectedFiles.length > 0){
        fileNames = selectedFiles.map(file => file.name);
        const { presigned_urls, file_s3_urls, id } = await getUploadPresignedUrls(fileNames);
        kbPreId = id;
        for (let i = 0; i < selectedFiles.length; i++) {
          const file = selectedFiles[i];
          const presignedUrl = presigned_urls[i];
    
          try {
            await uploadFileToS3(file, presignedUrl);
            filesS3Urls.push(file_s3_urls[i]);
          } catch (uploadError) {
            console.error(`Failed to upload ${file.name}:`, uploadError);
          }
        }
      }

      dataFilters = dataFiltersAdded.map((filter) => ({
        ...filter,
        keywords: processStringToArrayByCommaSplit(filter.keywords),
        authors: processStringToArrayByCommaSplit(filter.authors),
        source: filter.source,
        file_names: filter.source === DataSourceType.toBackendType(DataSourceType.CUSTOM_FILES) 
          ? fileNames
          : filter.file_names,
      }));


      const newKbCreated = await createKnowledgeBase(knowledgeBaseName, dataFilters, kbPreId);
      NotificationService.notifySuccess("Knowledge base created successfully");
      return newKbCreated;
    } catch (error) {
      console.error("Failed to create Knowledge base: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to create Knowledge base: ${errorMessage}`
      );
      return null;
    }
  };

  const handleDelete = (index) => {
    const newDataList = dataFiltersAdded.filter((_, i) => i !== index);
    setDataFiltersAdded(newDataList);
  };

  const handleInputChange = (setter, field, errorMessage) => (e) => {
    const value = e.target.value;
    setter(value);
    validateInput(value, field, setErrors, errorMessage);
  };

  const handleFilesChange = (files) => {
    setSelectedFiles(files);
  };

  const handleAddFilesComplete = () => {
    setCreatingCustomData(false);
    setIsReviewing(true);
  };

  return (
    <>
      <div className="flex flex-row p-4 items-center justify-between w-full max-md:flex-col max-md:items-start">
        <h1 className="text-2xl font-bold">Create Knowledge Base</h1>
        <div className="flex flex-1 text-xl text-center text-sky-500 rounded-none max-md:max-w-full"></div>
      </div>
      {creatingDataFilter || creatingCustomData ? (
        creatingDataFilter ? (
          <div className="flex flex-col bg-white h-[calc(120vh-270px)]  max-lg:h-[calc(100vh-175px)]">
            <div className="flex flex-col flex-1 items-center w-full max-md:max-w-full overflow-auto">
              <div className="flex flex-col flex-1 px-5 py-5 max-w-full border border-solid border-zinc-200 w-[896px]">
                <TitleWithProgres imgSrc={Step2} altText="Filter" />
                <div className="flex flex-col flex-1 mt-8 w-full max-md:max-w-full">
                  <div className="flex flex-col w-full max-md:max-w-full">
                    <div className="flex flex-col pt-6 pb-12 w-full bg-white min-h-[276px] max-md:max-w-full">
                      <div className="flex justify-between items-center w-full max-md:max-w-full">
                        <AddDataFilterTitle/>
                      </div>
                      <DataFilterForm
                        item={{
                          name, keywords, authors,
                          source: dataSourceSelected,
                          start_date: startDate,
                          end_date: endDate,
                        }}
                        handleSelect={handleSelect}
                        onChangeName={setFilterName}
                        setKeywords={setKeywords}
                        setAuthors={setAuthors}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        selectedFiles={selectedFiles}
                        handleFilesChange={handleFilesChange}
                        currentDataFilters={dataFiltersAdded}
                      />
                    </div>
                  </div>
                  <div className="flex justify-center items-center mt-8">
                    <Button
                      disabled={
                        name.trim().length === 0 ||
                        (DataSourceType.getTypeFromString(dataSourceSelected) === DataSourceType.NO_SELECTED) || 
                        (DataSourceType.getTypeFromString(dataSourceSelected) !== DataSourceType.CUSTOM_FILES && keywords.length === 0 ) ||
                        (DataSourceType.getTypeFromString(dataSourceSelected) === DataSourceType.CUSTOM_FILES && selectedFiles.length === 0)
                      }
                      className="w-40"
                      onClick={handleDataCreated}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        : (
          <div className="flex flex-col bg-white h-[calc(120vh-270px)]  max-lg:h-[calc(100vh-175px)]">
            <div className="flex flex-col flex-1 items-center w-full max-md:max-w-full overflow-auto">
              <div className="flex flex-col flex-1 px-5 py-5 max-w-full border border-solid border-zinc-200 w-[896px]">
                <TitleWithProgres imgSrc={Step2Upload} altText="Name" />
                <div className="flex flex-col flex-1 mt-8 w-full max-md:max-w-full">
                  <div className="flex flex-col w-full max-md:max-w-full">
                    <div className="flex flex-col pt-6 pb-12 w-full bg-white min-h-[276px] max-md:max-w-full">
                      <FileUploader
                        maxFiles={10}
                        maxFileSizeMB={10}
                        initialFiles={selectedFiles}
                        onFilesChange={handleFilesChange}
                        readonly={false}
                      />
                    </div>
                  </div>
                  <div className="flex justify-center items-center mt-8">
                    <Button
                      disabled={selectedFiles.length === 0}
                      className="w-40"
                      onClick={handleAddFilesComplete}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      ) : isReviewing ? (
        <div className="flex flex-col h-[calc(125vh-270px)]  max-lg:h-[calc(100vh-175px)] items-center   justify-center">
          <div className="flex overflow-auto flex-col px-5 pt-5 max-w-4xl border border-solid border-zinc-200">
            <TitleWithProgres 
              imgSrc={selectedFilter === FilterType.DATA_FILTERS ? Step3 : Step3Upload}
              altText={selectedFilter === FilterType.DATA_FILTERS ? "Filter" : "Upload"}
            />
            <div className="flex overflow-auto flex-col mt-8 w-full max-md:max-w-full">
              <div className="flex flex-col w-full max-md:max-w-full">
                <div className="flex overflow-auto flex-col pt-6 w-full bg-white max-md:max-w-full">
                  <div className="flex justify-between text-center items-center w-full text-center max-md:max-w-full">
                    <div className="flex flex-col flex-1 justify-center items-center w-full max-w-full">
                      <div className="text-2xl tracking-wide leading-none text-neutral-900">
                        Review Knowledge Base
                      </div>
                      <div className="mt-2 text-base leading-6 text-neutral-900 text-opacity-80 max-md:max-w-full">
                        Make sure your knowledge base looks just the way you
                        want it to, and click Save. You'll also be able to edit
                        this knowledge base later on
                      </div>
                    </div>
                  </div>
                  {/* <div className="flex flex-col mt-5 w-full text-xl tracking-wide leading-none text-neutral-900 max-md:max-w-full"> */}
                  {/* <div className="flex flex-col px-5 pt-4 pb-6 w-full bg-white border-b border-solid border-b-slate-300 max-md:max-w-full"> */}
                  {/* <div className="flex gap-10 items-center w-full max-md:max-w-full"> */}
                  {/* <div
                          className="flex-1 shrink self-stretch my-auto w-full min-w-[240px] max-md:max-w-full"
                          value={knowledgeBaseName}
                        ></div> */}
                  {/* </div> */}
                  <div className="w-full h-full mt-5 border-none bg-white text-xl tracking-wide leading-none">
                    Knowledge Base Name
                  </div>
                  <div className="flex flex-col px-5 justify-center mt-4 w-full text-xl tracking-wide leading-none text-neutral-900 max-md:max-w-full">
                    <div className="self-stretch px-3 py-4 w-full bg-white rounded-lg border border-solid border-slate-300 min-h-[40px] max-md:max-w-full">
                      <input
                        type="text"
                        className="w-full h-full border-none bg-white text-xl tracking-wide leading-none"
                        placeholder="Enter Knowledge Base name"
                        value={knowledgeBaseName}
                        onBlur={() =>
                          validateInput(
                            knowledgeBaseName,
                            "name",
                            setErrors,
                            "Knowledge Base name is required."
                          )
                        }
                        onChange={handleInputChange(
                          setKnowledgeBaseName,
                          "name",
                          "Knowledge Base name is required."
                        )}
                      />
                      {errors.name && (
                        <div className="text-red-500 mt-2">{errors.name}</div>
                      )}
                    </div>
                  </div>
                  <hr className="my-5"/>
                  {dataFiltersAdded.length !== 0 && ( <>
                    <div className="w-full h-full mt-4 border-none bg-white text-xl tracking-wide leading-none">
                      Data Filters
                    </div>
                    <div className="flex flex-col w-full max-md:max-w-full mt-6 space-y-4">
                      {dataFiltersAdded.map((item, index) => (
                        <div key={index} className="border-b border-solid">
                          <ExpandableListItem
                            index={index}
                            title={item.name}
                            isExpanded={expandedIndex === index}
                            handleToggleExpand={handleToggleExpand}
                          />
                          {expandedIndex === index && (
                            <DataFilterForm
                              item={item}
                              handleSelect={handleSelect}
                              selectedFiles={selectedFiles}
                              readOnly
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </>)}
                  <ActionButtonsGroup
                    cancelLabel="Back"
                    onCancel={handleCancel}
                    onSave={handleSave}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : !isSecondStep ? (
        <>
          <div className="flex flex-col bg-white">
            <div className="flex flex-col flex-1 items-center w-full max-md:max-w-full justify-center">
              <div className="flex flex-col flex-1 px-5 py-5 max-w-full border border-solid border-zinc-200 w-[896px]">
                <TitleWithProgres imgSrc={Step1} altText="Name" />
                <div className="flex flex-col flex-1 mt-8 w-full max-md:max-w-full justify-center">
                  <div className="flex flex-col w-full max-md:max-w-full">
                    <div className="flex flex-col pt-6 pb-12 w-full bg-white max-md:max-w-full">
                      <div className="flex w-full text-center max-md:max-w-full">
                        <div className="flex flex-col flex-1 shrink self-stretch my-auto w-full basis-0 min-w-[240px] max-md:max-w-full">
                          <div className="text-2xl tracking-wide leading-none text-neutral-900 max-md:max-w-full">
                            Name your Knowledge Base
                          </div>
                          <div className="mt-2 text-base leading-6 text-neutral-900 text-opacity-80 max-md:max-w-full">
                            Start by crafting a clear and concise name that
                            reflects the content you'll be storing. <br />
                            This could be a project name, a topic area, or
                            simply a descriptive title – the choice is yours!
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col justify-center mt-6 w-full text-xl tracking-wide leading-none text-neutral-900 max-md:max-w-full">
                        <div className="self-stretch px-5 py-7 w-full bg-white rounded-lg border border-solid border-slate-300 min-h-[60px] max-md:max-w-full">
                          <input
                            type="text"
                            className="w-full h-full border-none bg-white text-xl tracking-wide leading-none"
                            placeholder="Enter Knowledge Base name"
                            value={knowledgeBaseName}
                            onBlur={() =>
                              validateInput(
                                knowledgeBaseName,
                                "name",
                                setErrors,
                                "Knowledge Base name is required."
                              )
                            }
                            onChange={handleInputChange(
                              setKnowledgeBaseName,
                              "name",
                              "Knowledge Base name is required."
                            )}
                          />
                        </div>
                        {errors.name && (
                          <div className="text-red-500 mt-2">{errors.name}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center items-center">
                  <Button
                    disabled={knowledgeBaseName.trim().length === 0}
                    onClick={handleCreateNew}
                    className="w-40"
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col bg-white  h-[calc(125vh-270px)]  max-lg:h-[calc(100vh-175px)]">
            <div className="flex flex-col overflow-auto flex-1 items-center">
              <div className="flex flex-col flex-1 px-5 py-5 max-w-full border border-solid border-zinc-200 w-[896px]">
                <TitleWithProgres imgSrc={Step2} altText="Filter" />
                <div className="flex flex-col flex-1 mt-8">
                  <div className="flex flex-col">
                    <div className="flex flex-col justify-between w-full bg-white min-h-[276px] max-md:max-w-full">
                      <div className="flex justify-between items-center">
                        <AddDataFilterTitle/>
                      </div>
                      <AddDataFilterButton onClick={() => { setCreatingDataFilter(true); }} />
                      {dataFiltersAdded.length === 0 && (
                        <div className="mt-2 text-base leading-6 text-neutral-900 text-opacity-80 text-center text-red-500">
                          At least one data filter is required
                        </div>
                      )}
                      <div className="flex flex-col w-full max-md:max-w-full mt-6 space-y-4">
                        {dataFiltersAdded.map((item, index) => (
                          <div
                            key={index}
                            className="border-b border-gray-300 rounded-b-lg"
                          >
                            <ExpandableListItem
                              index={index}
                              title={item.name}
                              isExpanded={expandedIndex === index}
                              handleToggleExpand={handleToggleExpand}
                              handleDelete={handleDelete}
                            />
                            {expandedIndex === index && (
                              <DataFilterForm
                                item={item}
                                handleSelect={handleSelect}
                                selectedFiles={selectedFiles}
                                readOnly
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="flex justify-center items-center mt-6">
                      <Button
                        disabled={dataFiltersAdded.length === 0}
                        onClick={()=>{setIsReviewing(true);}}
                      >
                        Continue
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default KnowledgeBase;
