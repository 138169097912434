import Button from "../common/Button";
import ActionButtonsGroup from "../common/buttons/ActionButtonsGrup";

export function ActionControlsBuilder(cancelCallback, saveCallback) {
  const ActionControls = ({ store }) => {
    return (
      <div className="flex flex-row space-x-4">

        <ActionButtonsGroup
          onCancel={cancelCallback}
          onSave={saveCallback}
          saveLabel="Save"
          buttonSize="small"
        />
      </div>
    );
  };

  return ActionControls;
}
