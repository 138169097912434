import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { cloneDeep, find } from "lodash";
import { createStore } from "polotno/model/store";
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from "polotno";
import { DEFAULT_SECTIONS } from "polotno/side-panel";
import { Toolbar } from "polotno/toolbar/toolbar";
import { Workspace } from "polotno/canvas/workspace";
import { Tooltip } from "polotno/canvas/tooltip";
import { SidePanel } from "polotno/side-panel";

import { ResizeSection } from "./editor/ResizeSection";
import { TemplatesSectionBuilder } from "./editor/TemplatesSection";
import { TEMPLATES } from "../data/Templates";
import { GenAITextBoxBuilder } from "./editor/GenAITextBox";
import { ActionControlsBuilder } from "./editor/ActionControls";
import { POLOTNO_KEY } from "./../config/constants";
import * as NotificationService from "../utils/notificationService";
import {
  fetchCampaign,
  updateCampaignPost,
  generateTemplateContent,
} from "../services";

import Loading from "./common/Loading";

import "@blueprintjs/core/lib/css/blueprint.css";

const SKIP_DEFAULT_SECTIONS = [
  "templates",
  "size",
  "background",
  "elements",
  "layers",
];

const store = createStore({
  key: POLOTNO_KEY,
  showCredit: true,
});

function PostEditStep(props) {
  const navigate = useNavigate();
  const { campaignId } = useParams();

  const queryClient = useQueryClient();

  const [currTemplate, setCurrTemplate] = useState(cloneDeep(TEMPLATES[0]));
  const [isTemplateLoading, setIsTemplateLoading] = useState(true);

  const campaignResults = useQuery({
    queryKey: [`campaign-${campaignId}`],
    queryFn: async () => fetchCampaign(campaignId),
    staleTime: 10 * 1000,
  });
  const captionsResults = useQuery({
    queryKey: ["captions"],
    queryFn: async () => generateTemplateContent(campaignResults.data.post_caption),
    staleTime: 10 * 1000,
  });
  const captions = captionsResults.data;

  const generateContent = (caption, num) => {
    const keyWords = [
      caption.key_word_1,
      caption.key_word_2,
      caption.key_word_3,
    ];
    const callToActions = [
      caption.call_to_action_1,
      caption.call_to_action_2,
      caption.call_to_action_3,
    ];
    const posts = [caption.post_1, caption.post_2, caption.post_3];

    if (num === 1) {
      return keyWords[(num - 1) % keyWords.length];
    } else if (num >= 2 && num <= 7) {
      return callToActions[(num - 2) % callToActions.length];
    } else if (num > 7) {
      return posts[(num - 8) % posts.length];
    }
  };
  const campaign = campaignResults.data;

  const templateChangeCallback = async (template) => {
    setIsTemplateLoading(true);

    const _template = cloneDeep(template);

    let pageTextPage = find(_template.pages, ["id", "page-text"]);

    if (!pageTextPage) {
      pageTextPage = {
        id: "page-text",
        children: [
          {
            id: "post",
            type: "text",
            width: _template.width,
            text: campaign.post_caption,
            fontSize: Math.min(_template.width, _template.height) * 0.01,
            styleEditable: false,
            resizable: false,
            draggable: false,
          },
        ],
      };
      _template.pages.unshift(pageTextPage);
    }

    const textLength = campaign.post_caption.length;
    const numLines = (campaign.post_caption.match(/\n/g) || []).length + 1;

    _template.pages[0].children[0].fontSize =
      Math.min(_template.width / textLength, _template.height / numLines) * 16;

    let promises = [];

    _template.pages[1].children.forEach((element) => {
      const defaultImage = campaign.post_image;
      const editImage = campaign.post_edit_image;

      if (editImage) {
        if (element.type === "image") {
          element.src = editImage;
        }
      } else {
        if (element.type === "image") {
          element.src = defaultImage;
        }
      }

      if (element.type === "text") {
        const text = element.text;
        const numberOfWords = text.split(" ").length;
        const newText = generateContent(captions, numberOfWords);
        element.text = newText;
      }
    });

    return Promise.all(promises).then(() => {
      setIsTemplateLoading(false);
      setCurrTemplate(_template);

      return _template;
    });
  };
  useEffect(() => {
    if (campaign.post_edit_state) {
      setCurrTemplate(campaign.post_edit_state);
      templateChangeCallback(campaign.post_edit_state).then((t) => {
        store.loadJSON(t);
      });
    } else {
      templateChangeCallback(currTemplate)
        .then((t) => {
          store.loadJSON(t);
        })
        .catch((error) => {
          console.error("Failed to generate captions: ", error);
          const errorMessage = error.message || "An unexpected error occurred";
          NotificationService.notifyError(
            `Failed to generate captions: ${errorMessage}`
          );
        });
    }
  }, []);

  const updatedSections = DEFAULT_SECTIONS.filter((e) => {
    return !SKIP_DEFAULT_SECTIONS.includes(e.name);
  });
  updatedSections.push(ResizeSection);
  updatedSections.push(TemplatesSectionBuilder(templateChangeCallback));

  const cancelAction = () => {
    navigate(`/mkt/campaigns/${campaign.id}/assistant`);
  };

  const saveAction = async () => {
    try {
      setIsTemplateLoading(true);
      const postPayload = {
        post_edit_state: store.toJSON(),
        post_edit_image: await store.toDataURL({
          pageId: currTemplate.pages[1].id,
          mimeType: "image/jpg",
        }),
      };

      const postUpdated = await updateCampaignPost(campaign.id, 1, postPayload);
      queryClient.setQueryData([`campaign-${campaign.id}`], (old) => {
        return { ...old, ...postUpdated };
      });
      NotificationService.notifySuccess("Campaign updated successfully");
      navigate(`/mkt/campaigns/${campaign.id}/assistant`);
    } catch (error) {
      console.error("Failed to update campaign post: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to update campaign post: ${errorMessage}`
      );
    } finally {
      setIsTemplateLoading(false);
    }
  };

  return (
    <section className="w-[calc(100vw-450px)] h-[calc(100vh-100px)]">
      <PolotnoContainer className="polotno-app-container">
        <SidePanelWrap>
          <SidePanel
            store={store}
            sections={updatedSections}
            defaultSection="custom-templates"
          />
        </SidePanelWrap>
        <WorkspaceWrap>
          <Toolbar
            store={store}
            components={{
              ActionControls: ActionControlsBuilder(cancelAction, saveAction),
            }}
          />
          {(isTemplateLoading ||
            !captions ||
            Object.keys(captions).length === 0) && (
            <div
              style={{
                position: "absolute",
                top: "50px",
                zIndex: "20",
                width: "100%",
                height: "calc(100vh - 20px)",
              }}
              className="bg-gray-800 bg-opacity-50 backdrop-blur-sm flex justify-center items-center"
            >
              <Loading />
            </div>
          )}
          <Workspace
            store={store}
            components={{
              Tooltip,

              PageControls: () => null,
            }}
          />
        </WorkspaceWrap>
      </PolotnoContainer>
    </section>
  );
}

export default PostEditStep;
