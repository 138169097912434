import { fetchAuthSession } from "aws-amplify/auth";
import { API_SERVER } from "../config/constants";

export const fetchMessagesByThreadId = async (campaignId, activeThreadId) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/campaigns/${campaignId}/assistant/thread/${activeThreadId}/messages`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
      }
    );
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to fetchMessagesByThreadId:", error);
    throw error;
  }
};

export const sendMessage = async (campaignId, message, activeThreadId) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/campaigns/${campaignId}/assistant/message`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify({
          'message': message,
          'thread_id': activeThreadId,
        }),
      }
    );
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to sendMessage:", error);
    throw error;
  }
};

export const fetchMessageByRunId = async (campaignId, threadId, runId, knowledgeBaseId, useHtml) => {
  const session = await fetchAuthSession();
  let url = `${API_SERVER}/campaigns/${campaignId}/assistant/thread/${threadId}/message/${runId}`;
  url = knowledgeBaseId ? `${url}/knowledge_base/${knowledgeBaseId}` : `${url}/knowledge_base/default`;
  url = `${url}/use_html_template/${useHtml}`;

  const response = await fetch(
    url,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
    }
  );
  return response;
};

export const deleteMessage = async (threadId) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/assistant/thread/${threadId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.tokens.idToken.toString()}`,
        },
      }
    );
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to deleteMessage:", error);
    throw error;
  }
};
