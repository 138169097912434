import { useEffect, useState, useRef, useContext, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Button from "./common/Button";
import Typing from "./Typing";
import { AuthContext } from "../AuthProvider";
import PostPreviewSide from "./PostPreviewSide";
import { ReactComponent as AgentAvatar } from "../assets/agent_avatar.svg";
import { ReactComponent as ArrowBack } from "../assets/arrow_back.svg";
import { ReactComponent as MoreVertIcon } from "../assets/more_vert.svg";
import { Modal } from "flowbite-react";
import {
  fetchCampaign,
  createAssistantMessage,
  fetchMultiCampaign,
  sendMessage,
  fetchMessagesByThreadId,
  fetchKnowledgeBases,
  deleteMessage,
  createMultiCampaign,
  deleteCampaign,
  fetchPromptTemplates,
  fetchSystemPromptTemplates,
  fetchMessageByRunId,
} from "../services";
import * as NotificationService from "../utils/notificationService";
import UseHtmlTemplateCheckbox from "./Campaigns/UseHtmlTemplateCheckbox";
import SourcesList from "./Campaigns/SourcesList";
import AssistantMessageInput from "./Campaigns/AssistantMessageInput";
import PromptTemplatesDisplayer from "./Campaigns/PromptTemplates";
import { formatDateWithOrdinal, getTomorrowDate } from "../utils/common";
import AIModelType from "../data/enums/AI_model_type";

let valu = 0;

function PostAssistantStep(props) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [knowledgeBaseData, setKnowledgeBaseData] = useState([]);
  const [CombinedPromptTemplatesData, setCombinedPromptTemplatesData] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [kbId, setKbId] = useState("default");
  const [localMessage, setLocalMessage] = useState([]);
  const [isWatingAssistant, setIsWatingAssistant] = useState(false);
  const [threadId, setThreadID] = useState(null);
  const [aiModel, setAIModel] = useState(AIModelType.OPEN_AI);
  const [isAssistantFailed, setIsAssistantFailed] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  let { campaignId } = useParams();
  let { active_thread_id } = useParams();
  const [useHtmlForImage, setUseHtmlForImage] = useState(false);

  const getAIModel = {
    [AIModelType.OPEN_AI]: (message) => sendMessageWithOpenAI(message),
    [AIModelType.GROQ_AI]: (message) => sendMessageWithGroq(message),
  };
 
  const fetchCampaignHandler = async (campaignId) => {
    try {
      const campaign = await fetchCampaign(campaignId);
      const campaignIaModel = AIModelType.getTypeFromString(campaign.ai_model || AIModelType.OPEN_AI);
      setAIModel(campaignIaModel);
      return campaign;
    } catch (error) {
      console.error("Failed to fetch campaign: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to fetch campaign: ${errorMessage}`
      );
    }
  };

  const fetchMultiCampaignsHandler = async (campaignId) => {
    try {
      const multiCampaigns = await fetchMultiCampaign(campaignId);
      return multiCampaigns;
    } catch (error) {
      console.error("Failed to fetch campaign: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to fetch campaign: ${errorMessage}`
      );
    }
  };

  const campaignQuery = useQuery({
    queryKey: [`campaign-${campaignId}`],
    queryFn: async () => fetchCampaignHandler(campaignId),
  });

  const multiCampaignsQuery = useQuery({
    queryKey: [`multicampaign-${campaignId}`],
    queryFn: async () => fetchMultiCampaignsHandler(campaignId),
  });

  const canEdit = useMemo(() => {
    return campaignQuery.data.state !== "PUBLISHED";
  }, [campaignQuery.data.state]);

  if (!active_thread_id) {
    active_thread_id = campaignQuery.data.active_thread_id;
  }
  const messagesResults = useQuery({
    queryKey: [`messages-${campaignId}`],
    queryFn: async () => {
      const messages = await fetchMessagesByThreadId(
        campaignId,
        active_thread_id
      );
      return messages;
    },
    refetchOnWindowFocus: false,
  });
  const elementRef = useRef();

  let auth = useContext(AuthContext);

  const handleChange = (event) => {
    setMessageInput(event.target.value);
  };

  const fetchKnowledgeBase = async () => {
    try {
      const knowledgeBases = await fetchKnowledgeBases();
      return knowledgeBases;
    } catch (error) {
      console.error("Failed to fetch knowledge bases: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to fetch knowledge bases: ${errorMessage}`
      );
      return null;
    }
  };

  const fetchUserPromptTemplatesHandler = async () => {
    try {
      const userPromptTemplates = await fetchPromptTemplates();
      return userPromptTemplates.data;
    } catch (error) {
      console.error("Failed to fetch user prompt templates: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to fetch user prompt templates: ${errorMessage}`
      );
      return null;
    }
  };

  const fetchSystemPromptTemplatesHandler = async () => {
    try {
      const systemPromptTemplates = await fetchSystemPromptTemplates();
      return systemPromptTemplates.data;
    } catch (error) {
      console.error("Failed to fetch system prompt templates: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to fetch system prompt templates: ${errorMessage}`
      );
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const knowledgeBases = await fetchKnowledgeBase();
        if (Array.isArray(knowledgeBases)) {
          setKnowledgeBaseData(knowledgeBases);
        } else {
          console.error("Expected an array for knowledgeBaseData");
        }

        const userPromptTemplates = await fetchUserPromptTemplatesHandler();
        const systemPromptTemplates = await fetchSystemPromptTemplatesHandler();

        if (
          Array.isArray(userPromptTemplates) &&
          Array.isArray(systemPromptTemplates)
        ) {
          const combinedTemplates = [
            ...userPromptTemplates,
            ...systemPromptTemplates,
          ];
          setCombinedPromptTemplatesData(combinedTemplates);
        } else {
          if (!Array.isArray(userPromptTemplates)) {
            console.error("Expected an array for userPromptTemplates");
          }
          if (!Array.isArray(systemPromptTemplates)) {
            console.error("Expected an array for systemPromptTemplates");
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    setLocalMessage((_prev) => [
      {
        type: "assistant",
        message_type: "text",
        message: `Hello, tell me what’s on your mind or pick up a suggested prompt templates`,
      },
      ...messagesResults.data,
    ]);
    if (campaignQuery.data.active_thread_id?.includes("groq")) {
      setAIModel(AIModelType.GROQ_AI);
    }

    elementRef.current.scrollIntoView();
  }, [auth.user.given_name, messagesResults.data.length]);

  useEffect(() => {
    elementRef.current.scrollIntoView();
  }, []);

  const sendMessageWithOpenAI = (_message) => {
    setIsAssistantFailed(false);
    setLocalMessage((prev) => [
      ...prev,
      {
        type: "user",
        message: _message,
      },
    ]);
    setMessageInput("");
    setIsWatingAssistant(true);

    elementRef.current.scrollIntoView();
    sendMessage(
      multiCampaignsQuery.data[valu].id,
      _message,
      campaignQuery.data.active_thread_id || threadId
    )
      .then((data) => {
        setThreadID(data.thread_id);
        pollForCompletion(data.thread_id, data.run_id);
        elementRef.current.scrollIntoView();
      })
      .catch((error) => {
        setIsWatingAssistant(false);
        setIsAssistantFailed(true);
        elementRef.current.scrollIntoView();
        console.error("Failed fetch assistance message: ", error);
        const errorMessage = error.message || "An unexpected error occurred";
        NotificationService.notifyError(
          `Failed fetch assistance message: ${errorMessage}`
        );
      });
  };
  const toggleOptions = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };
  const sendMessageWithGroq = async (_message) => {
    if (!_message) {
      return;
    }
    setLocalMessage((prev) => [
      ...prev,
      {
        type: "user",
        message: _message,
      },
    ]);
    setMessageInput("");
    setIsWatingAssistant(true);
    const { data, thread_id } = await createAssistantMessage(
      campaignId,
      _message,
      kbId,
      useHtmlForImage
    );
    setThreadID(thread_id);
    queryClient.setQueryData([`messages-${campaignId}`], data);

    const lastMessage = data[data.length - 1];
    setLocalMessage([]);

    if (lastMessage.message_type === "json") {
      queryClient.setQueryData([`campaign-${campaignId}`], (old) => {
        return {
          ...old,
          post_social_network: lastMessage.message.social_network,
          post_caption: lastMessage.message.caption,
          post_image_prompt: lastMessage.message.image_prompt,
        };
      });
    }
    setIsWatingAssistant(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      getAIModel[aiModel](messageInput);
    }
  };

  const pollForCompletion = async (threadId, runId) => {
    try {
      const response = await fetchMessageByRunId(
        multiCampaignsQuery.data[valu].id,
        threadId,
        runId,
        kbId,
        useHtmlForImage
      );
      if (response.status === 202) {
        setTimeout(() => pollForCompletion(threadId, runId), 1000);
      } else if (response.status === 200) {
        const data = await response.json();
        queryClient.setQueryData([`messages-${campaignId}`], data);
        const lastMessage = data[data.length - 1];
        setLocalMessage([]);
        if (lastMessage.message_type === "json") {
          queryClient.setQueryData([`campaign-${campaignId}`], (old) => {
            return {
              ...old,
              post_social_network: lastMessage.message.social_network,
              post_caption: lastMessage.message.caption,
              post_image_prompt: lastMessage.message.image_prompt,
            };
          });
        }
        setIsWatingAssistant(false);
      } else {
        NotificationService.notifyError(
          `Failed to fetch assistance message, unexpected status code: ${response.status}`
        );
      }
    } catch (error) {
      console.error("Failed to fetch assistance message: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to fetch assistance message: ${errorMessage}`
      );
    }
  };

  const deleteThread = async (event) => {
    try {
      await deleteMessage(campaignQuery.data.active_thread_id || threadId);
      setLocalMessage([]);
      NotificationService.notifySuccess("Message deleted successfully");
    } catch (error) {
      console.error("Failed to delete message: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to delete message: ${errorMessage}`
      );
    }
  };

  const [openModal, setOpenModal] = useState(false);

  const [newCampaign, setNewCampaign] = useState({
    id: "",
    start_date: getTomorrowDate(),
    title: "",
    type: "SINGLE",
    multi_id: "",
  });

  const [inputValid, setInputValid] = useState({
    title: true,
    start_date: true,
  });

  const createCampaign = () => {
    const isTitleValid = newCampaign.title.trim() !== "";
    const isStartDateValid = newCampaign.start_date !== "";
    const istypeValid = (newCampaign.type = "MULTI");
    const ismultiidValid = (newCampaign.multi_id = campaignId);

    setInputValid({
      title: isTitleValid,
      start_date: isStartDateValid,
    });

    if (!newCampaign.title.trim()) {
      inputValid.title = false;
      return;
    }

    createMultiCampaign(newCampaign)
      .then((_) => {
        setOpenModal(false);
        multiCampaignsQuery.refetch();
        NotificationService.notifySuccess("Campaign created successfully");
      })
      .catch((error) => {
        console.error("Failed to create campaign: ", error);
        const errorMessage = error.message || "An unexpected error occurred";
        NotificationService.notifyError(
          `Failed to create campaign: ${errorMessage}`
        );
      });
  };

  const handleItemClick = (index) => {
    if (multiCampaignsQuery.data) {
      setSelectedIndex(index);
      valu = index;
      campaignId = multiCampaignsQuery.data[index].id;
      active_thread_id = multiCampaignsQuery.data[index].active_thread_id;
      campaignQuery.refetch();
      messagesResults.refetch();
    } else {
    }
  };
  const isDuplicate = multiCampaignsQuery.data.some(
    (item) => item.title === campaignQuery.data.title
  );

  // If it's not a duplicate, add the element
  if (!isDuplicate) {
    multiCampaignsQuery.data.push(campaignQuery.data);
  }
  window.addEventListener("popstate", () => {
    window.location.href = "/mkt/campaigns";
    window.location.reload();
  });

  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const deletecampaignHandler = (post) => {
    deleteCampaign(post.id)
      .then((_) => {
        setOpenModal(false);
        NotificationService.notifySuccess("Campaign deleted successfully");
      })
      .catch((error) => {
        console.error("Failed to delete campaign: ", error);
        const errorMessage = error.message || "An unexpected error occurred";
        NotificationService.notifyError(
          `Failed to delete campaign: ${errorMessage}`
        );
      });
  };
  const handlePromptChange = (templateId) => {
    const selectedItem = CombinedPromptTemplatesData.find(
      (item) => item.prompt_id === templateId
    );
    if (selectedItem) {
      setMessageInput(selectedItem.prompt_template_content);
    }
  };

  const handleKbChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "default") {
      setKbId(selectedValue);
    } else {
      const selectedItem = knowledgeBaseData.find(
        (item) => item.id === selectedValue
      );
      setKbId(selectedItem.id);
    }
  };

  const isKbSelected = kbId !== "default";
  const extractReferences = (data) => {
    let references = new Set();

    data.forEach((element) => {
      if (element.reference && Array.isArray(element.reference)) {
        element.reference.forEach((ref) => {
          references.add(JSON.stringify(ref));
        });
      }
    });

    return Array.from(references).map((ref) => JSON.parse(ref));
  };

  const allReferences = extractReferences(messagesResults?.data);

  const handleSendClick = () => {
    if (!isWatingAssistant && canEdit) {
      const event = new KeyboardEvent('keydown', {
        key: 'Enter',
        code: 'Enter',
      });
      handleKeyDown(event);
    }
  };

  return (
    <div className="flex flex-row w-full">
      <div className="h-full w-full flex flex-col">
        <div className="flex flex-row p-4 justify-between border-b-very-light-blue border-b">
          <div className="flex flex-row items-center">
            <ArrowBack
              onClick={() => {
                navigate("/mkt/campaigns");
                window.location.reload();
              }}
              className="hover:cursor-pointer"
            />
            <h1 className="pl-6 text-xl font-semibold">
              {campaignQuery.data.title}
            </h1>
            <div className="w-4 h-[0px] rotate-90 border border-gray-100"></div>
            <div className="max-md:hidden text-main-gray text-sm font-medium leading-snug tracking-tight">
              {formatDateWithOrdinal(campaignQuery.data.created_at)}
            </div>
          </div>
          <div className="flex flex-row space-x-3 max-md:hidden">
          </div>
        </div>
        <div className="max-md:100 flex flex-row">
          {campaignQuery.data.type === "MULTI" ? (
            <>
              <div className="flex flex-col w-1/3 h-full p-4">
                <Button outline onClick={() => setOpenModal(true)}>
                  + New Post
                </Button>

                <ul className="flex flex-col h-[calc(100vh-250px)] max-lg:h-[calc(100vh-175px)] overflow-y-auto">
                  {multiCampaignsQuery.data.map((post, index) => (
                    <a key={index} onClick={() => toggleOptions(index)}>
                      <li
                        className={`flex px-3 py-4 rounded-md mt-4 relative ${
                          selectedIndex === index ||
                          (selectedIndex === null &&
                            index === multiCampaignsQuery.data.length - 1)
                            ? "bg-blue-300"
                            : "bg-blue-100"
                        }`}
                      >
                        <span className="flex-grow">{post.title}</span>
                        <span className="absolute top-5 right-5">
                          <MoreVertIcon />
                        </span>
                        {openDropdown === index && (
                          <div className="absolute z-10 mt-2 w-48 bg-white rounded-lg shadow-lg">
                            <div className="py-1">
                              <div
                                onClick={() =>
                                  handleItemClick(index)
                                }
                                className="block px-4 py-2 text-gray-800 hover:bg-gray-200 cursor-pointer"
                              >
                                Edit
                              </div>
                              <div
                                onClick={() => {
                                  setOpenDropdown(null);
                                  deletecampaignHandler(post);
                                }}
                                className="block px-4 py-2 text-gray-800 hover:bg-gray-200 cursor-pointer"
                              >
                                DELETE
                              </div>
                            </div>
                          </div>
                        )}
                      </li>
                    </a>
                  ))}
                </ul>
              </div>

              <div className="flex flex-col w-2/3 h-full p-4">
                <div className="flex justify-end mb-4">
                  <div className="flex flex-row gap-4">
                    <select
                      className="p-2 border rounded-xl bg-white focus:outline-none"
                      onChange={handleKbChange}
                      value={kbId}
                    >
                      <option value="" disabled>
                        Select Knowledge Base
                      </option>
                      <option key="default" value="default">
                        No knowledge base
                      </option>
                      {knowledgeBaseData
                        .filter((item) => item.status === "ready")
                        .map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.knowledge_base_name}
                          </option>
                        ))}
                    </select>
                    <UseHtmlTemplateCheckbox
                      onCheckChange={setUseHtmlForImage}
                      disabled={!isKbSelected}
                    />
                  </div>
                </div>
                {messagesResults.data.length <= 0 ? (
                  <PromptTemplatesDisplayer
                    promptTemplates={CombinedPromptTemplatesData}
                    handlePromptChange={handlePromptChange}
                  />
                ) : (
                  <div></div>
                )}
                <ul
                  className={`flex flex-col overflow-y-auto rounded-xl p-4 ${
                    messagesResults.data.length <= 0
                      ? "h-[calc(70vh-250px)] max-lg:h-[calc(80vh-175px)]"
                      : "h-[calc(100vh-250px)] max-lg:h-[calc(80vh-175px)]"
                  }`}
                >
                  {localMessage.length !== 0 &&
                    localMessage.map((m, i) => (
                      <div key={i}>
                        <li
                          className={
                            m.type === "user"
                              ? "flex flex-row mt-3"
                              : "flex flex-row mt-3 rounded-xl bg-blue-50"
                          }
                        >
                          <span className="p-2">
                            {m.type === "user" ? (
                              <div className="w-8 h-8 p-2.5 bg-main-blue rounded-full flex items-center justify-center">
                                <div className="text-center text-white text-base font-normal">
                                  {auth.user.given_name[0]}
                                  {auth.user.family_name[0]}
                                </div>
                              </div>
                            ) : (
                              <AgentAvatar className="bg-black rounded-full w-8 h-8 px-1 py-1 shadow-md" />
                            )}
                          </span>
                          <span className="p-2 pt-2 inline-flex items-center whitespace-pre-wrap">
                            {typeof m.message === "object"
                              ? m.message.assistant_message
                              : m.message}
                          </span>
                        </li>
                        {m.reference && <SourcesList reference={m.reference} />}
                      </div>
                    ))}
                  {isWatingAssistant && (
                    <li className="flex flex-row mt-3 rounded-xl bg-very-light-blue">
                      <span className="py-4 pl-3">
                        <Typing />
                      </span>
                    </li>
                  )}
                  {isAssistantFailed && (
                    <li className="flex flex-row mt-3 rounded-xl bg-very-light-blue">
                      <span className="p-2">
                        <AgentAvatar className="bg-black rounded-3xl w-8 h-8 px-1 py-1 shadow-transparent" />
                      </span>
                      <span className="p-2">
                        <span className="pr-2">
                          It seems like something whent wrong.
                        </span>
                        <Button onClick={() => sendMessageWithOpenAI("retry")}>
                          Retry
                        </Button>
                      </span>
                    </li>
                  )}
                  <li ref={elementRef}></li>
                </ul>
                <AssistantMessageInput
                  messageInput={messageInput}
                  handleChange={handleChange}
                  handleKeyDown={handleKeyDown}
                  disabled={isWatingAssistant || !canEdit}
                  handleSendClick={handleSendClick}
                />
              </div>
            </>
          ) : (
            <div className="flex flex-col w-2/3 h-full p-4">
              <div className="flex justify-end mb-4">
                <div className="flex flex-row gap-4">
                  <select
                    className="p-2 border rounded-xl bg-white focus:outline-none"
                    onChange={handleKbChange}
                    value={kbId}
                  >
                    <option value="" disabled>
                      Select Knowledge Base
                    </option>
                    <option key="default" value="default">
                      No knowledge base
                    </option>
                    {knowledgeBaseData
                      .filter((item) => item.status === "ready")
                      .map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.knowledge_base_name}
                        </option>
                      ))}
                  </select>
                  <UseHtmlTemplateCheckbox
                    onCheckChange={setUseHtmlForImage}
                    disabled={!isKbSelected}
                  />
                </div>
              </div>
              {messagesResults.data.length <= 0 ? (
                <PromptTemplatesDisplayer
                  promptTemplates={CombinedPromptTemplatesData}
                  handlePromptChange={handlePromptChange}
                />
              ) : (
                <div></div>
              )}
              <ul
                className={`flex flex-col overflow-y-auto rounded-xl p-4 ${
                  messagesResults.data.length <= 0
                    ? "h-[calc(80vh-250px)] max-lg:h-[calc(80vh-175px)]"
                    : "h-[calc(100vh-250px)] max-lg:h-[calc(80vh-175px)]"
                }`}
              >
                {localMessage.length !== 0 &&
                  localMessage.map((m, i) => (
                    <div key={i}>
                      <li
                        className={
                          m.type === "user"
                            ? "flex flex-row mt-3"
                            : "flex flex-row mt-3 rounded-xl bg-blue-50"
                        }
                      >
                        <span className="p-2">
                          {m.type === "user" ? (
                            <div className="w-8 h-8 p-2.5 bg-main-blue rounded-full flex items-center justify-center">
                              <div className="text-center text-white text-base font-normal">
                                {auth.user.given_name[0]}
                                {auth.user.family_name[0]}
                              </div>
                            </div>
                          ) : (
                            <AgentAvatar className="bg-black rounded-full w-8 h-8 px-1 py-1 shadow-md" />
                          )}
                        </span>
                        <span className="p-2 pt-2 inline-flex items-center whitespace-pre-wrap">
                          {typeof m.message === "object"
                            ? m.message.assistant_message
                            : m.message}
                        </span>
                      </li>
                      {m.reference && <SourcesList reference={m.reference} />}
                    </div>
                  ))}

                {isWatingAssistant && (
                  <li className="flex flex-row mt-3 rounded-xl bg-blue-50">
                    <span className="py-4 pl-3">
                      <Typing />
                    </span>
                  </li>
                )}
                {isAssistantFailed && (
                  <li className="flex flex-row mt-3 rounded-xl bg-red-50">
                    <span className="p-2">
                      <AgentAvatar className="bg-black rounded-full w-8 h-8 px-1 py-1 shadow-md" />
                    </span>
                    <span className="p-2">
                      <span className="pr-2">
                        It seems like something went wrong.
                      </span>
                      <Button onClick={() => sendMessageWithOpenAI("retry")}>
                        Retry
                      </Button>
                    </span>
                  </li>
                )}
                <li ref={elementRef}></li>
              </ul>
              <AssistantMessageInput
                messageInput={messageInput}
                handleChange={handleChange}
                handleKeyDown={handleKeyDown}
                disabled={isWatingAssistant || !canEdit}
                handleSendClick={handleSendClick}
              />

            </div>
          )}
          <PostPreviewSide
            campaign={campaignQuery.data}
            className="m-4 w-1/3 rounded-3xl border border-mid-light-gray shadow-sm"
            useHtml={useHtmlForImage}
            references={allReferences}
            updateSubCampaignInfo={() => handleItemClick(selectedIndex)}
          />
        </div>
        <div className="md:hidden flex flex-row bg-transparent text-2xl align-middle items-center p-10 max-md:h-[calc(100vh-70px)] text-center">
          This functionality was designed mainly for tablets and desktops
        </div>
      </div>
      <Modal show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header className="text-center">Create Campaign</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <div className="relative">
              <input
                type="text"
                id="campaign_title"
                value={newCampaign.title}
                onChange={(e) =>
                  setNewCampaign({ ...newCampaign, title: e.target.value })
                }
                className={`relative ${
                  !inputValid.title
                    ? "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-red-500 appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer"
                    : "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer"
                }`}
                placeholder=" "
              />
              <label
                htmlFor="campaign_title"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-maborder-main-blue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >
                Title
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="w-full flex flex-row justify-end space-x-3">
            <Button outline onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
            <Button onClick={createCampaign}>Create</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PostAssistantStep;
