class DataSourceType {
  static X = "X";
  static ARXIV = "ARXIV";
  static CUSTOM_FILES = "CUSTOM_FILES";
  static NO_SELECTED = "NO_SELECTED";

  static toReadable(type) {
    switch (type) {
      case this.X:
        return 'X (Twitter)';
      case this.ARXIV:
        return "Arxiv";
      case this.CUSTOM_FILES:
        return "Custom Files";
      case this.NO_SELECTED:
        return "Select Data Source";
      default:
        return null;
    }
  }

  static toBackendType(type) {
    switch (type) {
      case this.X:
        return 'x';
      case this.ARXIV:
        return "arxiv";
      case this.CUSTOM_FILES:
        return "custom";
      default:
        return null;
    }
  }

  static getTypeFromString(typeString) {
    const option = typeString?.toLowerCase();
    switch (option) {
      case "x":
      case "twitter":
        return this.X;
      case "arxiv":
        return this.ARXIV;
      case "custom":
      case "custom_files":
        return this.CUSTOM_FILES;
      case "select data source":
        return this.NO_SELECTED;
      default:
        return null;
    }
  }
}

export default DataSourceType;