import React, { useState } from "react";
import { Modal } from "flowbite-react";
import { validateInput } from "../../utils/validations";
import Button from "../common/Button";
import { useNavigate } from "react-router-dom";
import { createCampaign } from "../../services/index";
import * as NotificationService from "../../utils/notificationService";
import AIModelType from "../../data/enums/AI_model_type";
import RadioInputGroup from "../common/forms/RadioButtonGroup";
import AIDropdownSelector from "./AIDropdownSelector";
import ActionButtonsGroup from "../common/buttons/ActionButtonsGrup";

const CreateCampaignModal = ({ openModal, setOpenModal, refetch }) => {
  const navigate = useNavigate();
  const initialErrors = { title: "Campaign title is required.", type: "" };
  const [errors, setErrors] = useState(initialErrors);
  const [inputValid, setInputValid] = useState({
    title: true,
    start_date: true,
  });
  const initalCampaign = {
    id: "",
    start_date: new Date().toISOString().split("T")[0],
    title: "",
    type: "SINGLE",
    ai_model: AIModelType.OPEN_AI,
  };
  const [newCampaign, setNewCampaign] = useState(initalCampaign);

  const handleInputChange = (setter, field, errorMessage) => (e) => {
    const value = e.target.value;
    setter((prevState) => ({ ...prevState, [field]: value }));
    validateInput(value, field, setErrors, errorMessage);
  };

  const createCampaignHandler = async () => {
    try {
      const isTitleValid = newCampaign.title.trim() !== "";
      const isStartDateValid = newCampaign.start_date !== "";

      // Update inputValid state based on validation
      setInputValid({
        title: isTitleValid,
        start_date: isStartDateValid,
        // Add other fields as needed
      });

      if (!isTitleValid || !isStartDateValid) {
        return; // Don't proceed if validation fails
      }

      const campaignCreated = await createCampaign(newCampaign);
      NotificationService.notifySuccess("Campaign created successfully");
      // Campaign created successfully
      setOpenModal(false);
      refetch();

      // Capture the campaign ID
      const campaignId = campaignCreated.id;

      // Navigate to the specified page using the captured ID
      navigate(`/mkt/campaigns/${campaignId}/assistant`);
    } catch (error) {
      console.error("Failed to create campaign: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to create campaign: ${errorMessage}`
      );
    }
  };

  const handleCancel = () => {
    setOpenModal(false);
    setNewCampaign(initalCampaign);
    setErrors(initialErrors);
  };

  return (
    <Modal show={openModal} onClose={handleCancel}>
      <Modal.Header className="text-center">Create Campaign</Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <div className="relative">
            <input
              type="text"
              id="campaign_title"
              value={newCampaign.title}
              onChange={handleInputChange(
                setNewCampaign,
                "title",
                "Campaign title is required."
              )}
              onBlur={() =>
                validateInput(
                  newCampaign.title,
                  "title",
                  setErrors,
                  "Campaign title is required."
                )
              }
              className={`relative ${
                !inputValid.title
                  ? "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-red-500 appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer"
                  : "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer"
              }`}
              placeholder=" "
            />
            <label
              htmlFor="campaign_title"
              className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-maborder-main-blue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              Title
            </label>
          </div>
          {errors.title && (
            <div className="text-red-500 mt-2">{errors.title}</div>
          )}
          <div className="flex flex-col gap-4 md:flex-row md:gap-20">
            <RadioInputGroup
              name="postType"
              legend="Campaign Type"
              options={[
                { id: "single", value: "SINGLE", label: "Single Post" },
                { id: "multi", value: "MULTI", label: "Multiple Post" },
              ]}
              selectedValue={newCampaign.type}
              onChange={handleInputChange(setNewCampaign, "type")}
            />
            <AIDropdownSelector
              selectedValue={newCampaign.ai_model}
              onChange={handleInputChange(setNewCampaign, "ai_model")}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-full flex-row justify-end space-x-3">
          <ActionButtonsGroup
            saveLabel="Create"
            saveDisabled={newCampaign.title.trim().length === 0}
            onSave={createCampaignHandler}
            onCancel={handleCancel}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateCampaignModal;
