import React, { useState, useEffect } from 'react';

const UseHtmlTemplateCheckbox = ({ onCheckChange, disabled }) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (disabled) {
      setIsChecked(false);
      onCheckChange(false);
    }
  }, [disabled, onCheckChange]);

  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    setIsChecked(checked);
    if (onCheckChange) onCheckChange(checked);
  };

  return (
    <label className={`inline-flex items-center ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}>
      <input 
        type="checkbox" 
        checked={isChecked} 
        onChange={handleCheckboxChange} 
        className="sr-only peer" 
        disabled={disabled} 
      />
      <div 
        className={`
          relative w-11 h-6 
          ${disabled ? 'bg-gray-300' : isChecked ? 'bg-main-blue' : 'bg-gray-200'} 
          peer-focus:outline-none peer-focus:ring-4 
          ${disabled ? 'peer-focus:ring-gray-400' : 'peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800'} 
          rounded-full peer 
          ${disabled ? 'dark:bg-gray-600' : 'dark:bg-gray-700'} 
          peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full 
          peer-checked:after:border-white 
          after:content-[''] after:absolute after:top-[2px] after:start-[2px] 
          after:bg-white after:border-gray-300 after:border after:rounded-full 
          after:h-5 after:w-5 after:transition-all 
          ${disabled ? 'after:bg-gray-400 dark:border-gray-500' : 'dark:border-gray-600'} 
        `}
      ></div>
      <span 
        className={`ms-3 text-sm font-medium ${disabled ? 'text-gray-500' : 'text-main-blue'}`}
      >
        HTML Template
      </span>
    </label>
  );
};

export default UseHtmlTemplateCheckbox;