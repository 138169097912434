import React from "react";
import { useNavigate } from "react-router-dom";

const AddSocialAccountButton = () => {
  const navigate = useNavigate();
  return (
    <div className="lg:basis-[43%] xl:basis-[30%] lg:ml-3 flex items-center justify-center ">
      <button
        onClick={() => {
          navigate(`/mkt/settings`);
        }}
        className="flex items-center font-medium text-main-blue hover:text-dark-blue focus:outline-none"
        aria-label="Add Social account"
      >
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/593c3f3b58c11c1ae6bc395912fafb08b42b2ef70cc94fdd727b61925fe340fb?apiKey=ed65200cdc83432eae597ee1cdaa24d7&&apiKey=ed65200cdc83432eae597ee1cdaa24d7"
          className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square"
        />
        <span className="ml-2">Add Social account</span>
      </button>
    </div>
  );
};

export default AddSocialAccountButton;
