import { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Image from "./image_generation/Image";
import SocialNetworkSelector from "./SocialNetworkSelector";
import Button from "./common/Button";
import { ReactComponent as EditIcon } from "../assets/edit_icon.svg";
import { ReactComponent as ScheduledStatusIcon } from "../assets/scheduled_status_icon.svg";
import AddSocialAccountButton from "./Campaigns/AddSocialAccountButton";
import { Modal } from "flowbite-react";
import {
  updateCampaign,
  schedulePost,
  publishCampaignPost,
  updateCampaignPost,
  fetchApps,
} from "../services";
import * as NotificationService from "../utils/notificationService";
import SourcesContainer from "./Campaigns/SourcesContainer";
import { getTomorrowDate } from "../utils/common";
import CaptionContainer from "./Campaigns/CaptionContainer";
import {
  getSocialPlatformName,
} from "../utils/socialPlatformUtils";
import { isEmpty } from "../utils/validations";

const PublishedIcon = () => (
  <svg
    className="w-[18px] h-[18px] mx-1 text-green-500 dark:text-white"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
  >
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
  </svg>
);

function convertDateToISO(startDate, startTime) {
  const datetimeStr = `${startDate}T${startTime}:00`;
  const datetimeObj = new Date(datetimeStr);
  return datetimeObj.toISOString();
}

function PostPreviewSide({
  campaign: campaignData,
  className,
  useHtml,
  references,
  updateSubCampaignInfo,
}) {
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const [campaign, setCampaign] = useState(campaignData);
  const [showMoreText, setShowMoreText] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [showReferences, setShowReferences] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [postCaption, setPostCaption] = useState(campaign.post_caption);

  const appsQuery = useQuery({
    queryKey: ['socialAccounts'],
    queryFn: fetchApps,
    staleTime: 10 * 1000,
  });

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      setCopySuccess(false);
    }
  };

  const handleSaveEditedCaption = async () => {
    await updateCampaignPost(campaign.id, 1, { post_caption: postCaption });
  };

  const handleSocialNetwork = async (socialAccountId) => {
    try {
      const response = await updateCampaign(campaign.id, getSocialPlatformName(socialAccountId), socialAccountId);
      queryClient.setQueryData([`campaign-${campaign.id}`], (old) => {
        return { ...old, ...response };
      });
      if (campaign.type === "MULTI") {
        updateSubCampaignInfo()
      }
    } catch (error) {
      console.error("Failed to update social network: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to update social network: ${errorMessage}`
      );
    }
  };
  useEffect(() => {
    setPostCaption(campaignData.post_caption);
    setCampaign(campaignData);
  }, [campaignData]);

  const [schedule_Post, setschedule_Post] = useState({
    id: "",
    schedule_time: "",
    email: "",
    post_social_network: "",
    post_image_key: "",
    post_image_bucket: "",
    post_caption: "",
    start_date: "",
    start_time: "",
  });

  const schedulePostHandler = () => {
    schedule_Post.id = campaign.id;
    schedule_Post.email = campaign.email;
    schedule_Post.schedule_time = convertDateToISO(
      schedule_Post.start_date,
      schedule_Post.start_time
    );
    schedule_Post.post_caption = campaign.post_caption;
    schedule_Post.post_image_bucket = campaign.post_image_bucket;
    schedule_Post.post_image_key = campaign.post_image_key;
    schedule_Post.post_social_network = campaign.post_social_network;
    schedule_Post.social_account_id = campaign.social_account_id;

    schedulePost(schedule_Post)
      .then(() => {
        setOpenModal(false);
        // refetch();
        NotificationService.notifySuccess("Post scheduled successfully!");
      })
      .catch((error) => {
        console.error("Failed to schedule post: ", error);
        const errorMessage = error.message || "An unexpected error occurred";
        NotificationService.notifyError(
          `Failed to schedule post: ${errorMessage}`
        );
      });
  };

  const publishPostHandler = () => {
    setIsPublishing(true);
    const publish = async () => {
      try {
        const post = await publishCampaignPost(campaign.id, 1, showReferences);
        queryClient.setQueryData([`campaign-${campaign.id}`], (old) => {
          return { ...old, ...post };
        });
        NotificationService.notifySuccess("Post published successfully!");
      } catch (error) {
        console.error("Failed to publish post: ", error);
        const errorMessage = error.message || "An unexpected error occurred";
        NotificationService.notifyError(
          `Failed to publish post: ${errorMessage}`
        );
      } finally {
        setIsPublishing(false);
      };
    };

    publish();
  };

  const renderState = () => {
    switch (campaign.state) {
      case "PUBLISHED":
        return (
          <>
            <PublishedIcon />
            Published
          </>
        );
      default:
        return (
          <>
            <ScheduledStatusIcon /> Draft
          </>
        );
    }
  };

  const canEdit = useMemo(() => {
    if (isPublishing) return !isPublishing;

    return campaign.state !== "PUBLISHED";
  }, [campaign.state, isPublishing]);

  const [openModal, setOpenModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenedit, setisOpenedit] = useState(false);

  const currentSocialApp = campaign.social_account_id 
    ? appsQuery?.data?.find(account => account.app === campaign.social_account_id)
    : null;
  return (
    <div className={className + "flex flex-col justify-start"}>
      <div className="relative mb-1">
        <div className="px-4 pt-2 sm:px-1 flex flex-wrap flex-row justify-between gap-1 items-center">
          {campaign.post_social_network ? (
            appsQuery?.data.length === 0 && campaign.state !== "PUBLISHED"? (
              <AddSocialAccountButton/>
            ) : (
              <SocialNetworkSelector
                campaignStatus={campaign.state}
                appsQuery={appsQuery}
                disabled={!canEdit || appsQuery?.data.length === 0}
                socialAccountId={campaign.social_account_id}
                handleSocialNetwork={handleSocialNetwork}
                className="w-32 h-11 rounded-3xl border lg:basis-[48%] xl:basis-3/12"
              />
            )
          ) : (
            <div className="w-32 h-11 rounded-3xl bg-mid-light-gray lg:basis-[48%] xl:basis-3/12"></div>
          )}

          {campaign.post_caption && campaign.post_image && canEdit ? (
            <div className="lg:basis-[43%] xl:basis-[30%] lg:ml-3 flex items-center justify-center ">
              <button
                onClick={() => {
                  navigate(`/mkt/campaigns/${campaign.id}/edit`);
                  window.location.reload();
                }}
                className="flex items-center font-medium text-main-blue hover:text-dark-blue focus:outline-none"
                aria-label="Edit design studio"
              >
                <EditIcon className="text-light-gray hover:text-dark-gray transition-colors duration-200" />
                <span className="ml-2">Design Studio</span>
              </button>
            </div>
          ) : (
            <div className="w-11 h-11 rounded-3xl bg-mid-light-gray lg:basis-[43%] xl:basis-[10%]"></div>
          )}

          {campaign.post_caption && campaign.post_image ? (
            <div
              className="relative"
              style={{ color: "#1DA1F2", borderColor: "#1DA1F2" }}
            >
              <Button 
                onClick={() => setIsOpen(!isOpen)} 
                className="w-40"
                disabled={!canEdit || !(campaign.social_account_id && currentSocialApp?.account_name)}
              >
                Publish
              </Button>
              {isOpen && (
                <div className="absolute top-full left-0 mt-1 w-40 bg-white border border-gray-300 rounded-md shadow-lg z-10">
                  <ul className="py-1">
                    <li
                      className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                      disabled={!canEdit}
                      loading={isPublishing}
                      onClick={() => {
                        setOpenModal(true);
                        setIsOpen(!isOpen);
                      }}
                    >
                      Schedule Post
                    </li>
                    <li
                      className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                      disabled={!canEdit}
                      loading={isPublishing}
                      onClick={() => {
                        publishPostHandler();
                        setIsOpen(!isOpen);
                      }}
                    >
                      Publish
                    </li>
                  </ul>
                </div>
              )}
            </div>
          ) : (
            <div className="w-24 h-11 rounded-3xl bg-mid-light-gray"></div>
          )}
        </div>
      </div>
      {campaign.post_image_prompt ? (
        <Image campaign={campaign} disabled={canEdit} useHtml={useHtml} />
      ) : (
        <div className="h-[463px] bg-mid-light-gray"></div>
      )}

      <CaptionContainer
        postCaption={postCaption}
        originalCaption={campaign.post_caption}
        isEditing={isEditing}
        setPostCaption={setPostCaption}
        setShowMoreText={setShowMoreText}
        showMoreText={showMoreText}
        setIsEditing={setIsEditing}
        renderState={renderState}
        saveEdition={handleSaveEditedCaption}
      />
      {(references.length > 0 && !isEmpty(references[0])) && (
        <SourcesContainer
          references={references}
          showReferences={showReferences}
          setShowReferences={setShowReferences}
          copyToClipboard={copyToClipboard}
          copySuccess={copySuccess}
        />
      )}

      <Modal show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header className="items-center">Schedule Post</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <div className="relative">
              <input
                type="date"
                value={schedule_Post.start_date}
                onChange={(e) =>
                  setschedule_Post({
                    ...schedule_Post,
                    start_date: e.target.value,
                  })
                }
                min={getTomorrowDate()}
                id="campaign_start_date"
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer"
                placeholder=" "
              />
              <label
                htmlFor="campaign_start_date"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-maborder-main-blue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >
                Start Date
              </label>
            </div>
            <div className="relative">
              <input
                type="time"
                value={schedule_Post.start_time}
                onChange={(e) =>
                  setschedule_Post({
                    ...schedule_Post,
                    start_time: e.target.value,
                  })
                }
                id="campaign_start_time"
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer"
                placeholder=" "
              />
              <label
                htmlFor="campaign_start_date"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-maborder-main-blue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >
                Start Time
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="w-full flex flex-row justify-end space-x-3">
            <Button outline onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
            <Button onClick={schedulePostHandler}>Schedule Post</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PostPreviewSide;
