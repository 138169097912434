import React from 'react';

class SVGButton extends React.Component {
  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    const { width, height, fillColor, borderRadius, children } = this.props;

    return (
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        xmlns="http://www.w3.org/2000/svg"
        onClick={this.handleClick}
        style={{ cursor: 'pointer' }}
      >
        <rect width={width} height={height} rx={borderRadius} fill={fillColor} />
        <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fill="white">
          {children}
        </text>
      </svg>
    );
  }
}

SVGButton.defaultProps = {
  width: 256,
  height: 56,
  fillColor: '#6C2BD9',
  borderRadius: 12,
};

export default SVGButton;
