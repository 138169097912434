import { useState, useMemo, useEffect, useRef } from "react"

import { ReactComponent as LinkedInIcon } from '../assets/linkedin_icon.svg'
import { ReactComponent as ArrowDownIcon } from '../assets/arrow_drop_down.svg'
import { ReactComponent as XIcon } from '../assets/x.svg'
import {
  getSocialPlatformName,
} from "../utils/socialPlatformUtils";
import { truncateContent } from "../utils/common";

const iconMap = {
  'LinkedIn': LinkedInIcon,
  'X': XIcon,
}

function SocialNetworkSelector({ campaignStatus = "DRAFT", appsQuery, socialAccountId, handleSocialNetwork, disabled, className }) {
  const account = appsQuery?.data?.find(account => account.app === socialAccountId);
  const defaultAccountNameLabel = campaignStatus === "PUBLISHED" ? "Social account deleted" : "Select one";
  const socialAccountNameSelected = account?.account_name ?? defaultAccountNameLabel;

  const [showDropDown, setShowDropDown] = useState(false);
  const dropdownRef = useRef(null);

  const dropDownState = useMemo(() => {
    return showDropDown ? 'block' : 'hidden';
  }, [showDropDown]);

  const Icon = iconMap[getSocialPlatformName(socialAccountId)] ?? LinkedInIcon;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropDown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    disabled ? (
      <div 
        className={`${className} relative flex flex-row items-center justify-between p-2.5 ${
          disabled ? "opacity-50 cursor-not-allowed" : ""
        }`}
        title={socialAccountNameSelected}
      >
        <Icon className="h-5 w-5" />
        <span className={`ml-2 flex-1 truncate ${disabled ? "text-gray-400" : ""}`}>
          {truncateContent(socialAccountNameSelected, 10).truncatedText}
        </span>
      </div>
    ) : (
      <div ref={dropdownRef} className={`${className} relative flex flex-col p-2.5 hover:cursor-pointer`}>
        <div 
          onClick={() => setShowDropDown(!showDropDown)} 
          className="flex items-center"
          title={socialAccountNameSelected}
        >
          {socialAccountNameSelected !== "Select one" && <Icon className="h-5 w-5" />}
          <span className="ml-2 flex-1 truncate">{truncateContent(socialAccountNameSelected, 10).truncatedText}</span>
          <ArrowDownIcon className="text-gray-500 h-6 w-6" />
        </div>
        <div
          className={`${dropDownState} absolute top-full left-0 w-56 max-h-48 overflow-y-auto border rounded-lg bg-white z-20 shadow-md mt-1 scrollbar-hide`}
        >
          {appsQuery.data.map((item) => {
            const IconComponent = iconMap[getSocialPlatformName(item.app)] || LinkedInIcon;
            return (
              <div
                key={item.app}
                onClick={() => { setShowDropDown(false); handleSocialNetwork(item.app); }}
                className={
                  socialAccountId === item.app
                    ? 'bg-light-blue flex items-center h-10 p-1.5 w-full hover:bg-main-blue'
                    : 'flex items-center w-full h-10 p-1.5 hover:bg-main-blue'
                }
              >
                <IconComponent className="h-6 w-6" />
                <span className="ml-2 truncate">{truncateContent(item.account_name, 20).truncatedText}</span>
              </div>
            );
          })}
        </div>
      </div>
    )
  );
}

export default SocialNetworkSelector