import React from "react";
import AIModelType from "../../data/enums/AI_model_type";

const AIDropdownSelector = ({ selectedValue, onChange }) => {
    return (
        <div className="relative">
            <label
                htmlFor="ai_model"
                className="block mb-2 text-sm font-medium text-gray-900"
            >
                AI Model
            </label>
            <select
                id="ai_model"
                value={selectedValue}
                onChange={onChange}
                className="block w-full px-4 py-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-main-blue focus:border-main-blue"
            >
                <option value={AIModelType.OPEN_AI}>
                    {AIModelType.toReadable(AIModelType.OPEN_AI)}
                </option>
                <option value={AIModelType.GROQ_AI}>
                    {AIModelType.toReadable(AIModelType.GROQ_AI)}
                </option>
            </select>
        </div>
    );
};

export default AIDropdownSelector;