import React, { useEffect, useState } from "react";
import { validateInput } from "../../utils/validations";
import { createPromptTemplate } from "../../services";
import { CLOSE_ICON_URL } from "../../config/ExternalResources";
import Button from "../common/Button";
import * as NotificationService from "../../utils/notificationService";
import { READABLE_SITE_NAME } from "../../config/constants";
import ActionButtonsGroup from "../common/buttons/ActionButtonsGrup";

const NewTemplate = ({ closeModal }) => {
  const [promptName, setPromptName] = useState("");
  const [promptDescription, setPromptDescription] = useState("");
  const initialErrors = {
    name: "Prompt template name is required",
    description: "Prompt template description is required",
  };
  const [errors, setErrors] = useState(initialErrors);
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false);

  useEffect(() => {
    setIsCreateButtonDisabled(!promptName.trim() || !promptDescription.trim());
  }, [promptName, promptDescription]);

  const handleSave = async () => {
    try {
      await createPromptTemplate(promptName, promptDescription);
      NotificationService.notifySuccess("Prompt template created successfully");
      closeModal();
    } catch (error) {
      console.error("Failed to save prompt template: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to save prompt template: ${errorMessage}`
      );
    }
  };

  const handleInputChange = (setter, field, errorMessage) => (e) => {
    const value = e.target.value;
    setter(value);
    validateInput(value, field, setErrors, errorMessage);
  };

  const handleCancel = () => {
    closeModal();
    setPromptName("");
    setPromptDescription("");
    setErrors(initialErrors);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="relative bg-white rounded-2xl shadow-lg w-full max-w-[45%] p-6">
        <div className="text-center text-2xl font-bold mb-4">
          New Prompt Template
        </div>
        <img
          loading="lazy"
          onClick={handleCancel}
          src={CLOSE_ICON_URL}
          className="object-contain w-6 h-6 absolute top-3 right-3 cursor-pointer"
          alt="Close modal"
        />
        <div className="space-y-6">
          <div>
            <label className="block text-black">Prompt Name</label>
            <textarea
              className="mt-2 w-full bg-white border border-gray-300 rounded-lg p-2"
              placeholder="Give your prompt a name that you can easily recognize"
              value={promptName}
              onBlur={() =>
                validateInput(
                  promptName,
                  "name",
                  setErrors,
                  "Prompt template name is required"
                )
              }
              onChange={handleInputChange(
                setPromptName,
                "name",
                "Prompt template name is required"
              )}
            />
            {errors.name && (
              <div className="text-red-500 mt-2">{errors.name}</div>
            )}
          </div>
          <div>
            <label className="block text-black">Prompt Description</label>
            <textarea
              className="mt-2 w-full bg-white border border-gray-300 rounded-lg p-2"
              rows="6"
              value={promptDescription}
              onBlur={() =>
                validateInput(
                  promptDescription,
                  "description",
                  setErrors,
                  "Prompt template description is required"
                )
              }
              onChange={handleInputChange(
                setPromptDescription,
                "description",
                "Prompt template description is required"
              )}
              placeholder={`Write your prompt details as the question you would like to ask ${READABLE_SITE_NAME}’s AI in order to get a fine-tuned response.`}
            />
            {errors.description && (
              <div className="text-red-500 mt-2">{errors.description}</div>
            )}
          </div>
        </div>
        <div className="flex flex-col space-y-4 mt-4 max-w-[208px] mx-auto">
          <ActionButtonsGroup
            saveLabel="Create"
            saveDisabled={isCreateButtonDisabled}
            onCancel={handleCancel}
            onSave={handleSave}
          />
        </div>
      </div>
    </div>
  );
};

export default NewTemplate;
