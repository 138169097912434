import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Modal } from "flowbite-react";
import Button from "../../components/common/Button";
import EditKnowledgeBase from "./EditKnowledgeBase";
import { ADD_BLUE_ICON_URL } from "../../config/ExternalResources";
import * as NotificationService from "../../utils/notificationService";
import { fetchKnowledgeBases, deleteKnowledgeBase } from "../../services";
import KnowledgeBaseCard from "./KnowledgeBaseCard";

function KnowledgeBase({ className }) {
  const location = useLocation();
  let newKbCreated = location.state?.newKbCreated;
  const navigate = useNavigate();
  const [knowledgeBases, setKnowledgeBases] = useState([]);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [deletingId, setDeletingId] = useState(null); // Track which id is being deleted
  const [isLoading, setIsLoading] = useState(true);

  const hideDropdown = () => setActiveDropdown(null);

  const handleCreateNewClick = () => {
    navigate(`/mkt/createknowledgeBase`);
  };

  const toggleDropdown = (templateId) => {
    setActiveDropdown((prevId) => (prevId === templateId ? null : templateId));
  };

  const closeEditModal = () => {
    setCurrentTemplate(null);
    setShowEditModal(false);
  };

  const handleNewKnowledgeBase = (knowledgeBasesResponse) => {
    if (newKbCreated) {
      const exists = knowledgeBasesResponse.some((kb) => kb.id === newKbCreated.id);
      if (!exists) {
        knowledgeBasesResponse.push(newKbCreated);
      }
      newKbCreated = null;
    }
    return knowledgeBasesResponse;
  };

  const fetchKnowledgeBasesHandler = async () => {
    setIsLoading(true);
    try {
      let knowledgeBasesResponse = await fetchKnowledgeBases();
      knowledgeBasesResponse = handleNewKnowledgeBase(knowledgeBasesResponse);
      setKnowledgeBases(knowledgeBasesResponse); // Update state with fetched knowledge bases
    } catch (error) {
      console.error("Failed to fetch knowledge bases: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to fetch knowledge bases: ${errorMessage}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  const confirmDelete = async () => {
    try {
      await deleteKnowledgeBase(deletingId);
      NotificationService.notifySuccess("Knowledge base deleted successfully");
      setKnowledgeBases((prev) => prev.filter((kb) => kb.id !== deletingId)); // Remove the deleted item from state
      setOpenModal(false);
    } catch (error) {
      console.error("Failed to delete knowledge base: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to delete knowledge base: ${errorMessage}`
      );
    }
  };

  useEffect(() => {
    fetchKnowledgeBasesHandler();
  }, [navigate]);

  return (
    <div
      className={`${className} flex flex-col rounded-md shadow shadow-yet-yet-another-gray`}
    >
      <div className="flex flex-col rounded-md shadow shadow-yet-yet-another-gray">
        <div className="flex flex-row p-4 justify-between">
          <h1 className="text-2xl font-bold">Knowledge Bases</h1>
          <div className="flex overflow-hidden flex-col flex-1 w-full text-xl text-center text-sky-500 rounded-none max-md:max-w-full"></div>
        </div>
        <div className="flex flex-row rounded-md shadow p-5 shadow-yet-yet-another-gray">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
            <div onClick={handleCreateNewClick}>
              <div className="flex flex-col justify-center max-w-full bg-white rounded min-h-[220px] rounded-md shadow shadow-yet-yet-another-black shadow-lg w-[284px] cursor-pointer">
                <img
                  loading="lazy"
                  src={ADD_BLUE_ICON_URL}
                  className="object-contain self-center w-12 aspect-square"
                  alt="Create New Knowledge Base"
                />
                <div className="mt-5 w-full text-center font-bold text-[#1DA1F2]">
                  Create New <br />
                  Knowledge Base
                </div>
              </div>
            </div>
            {isLoading ? (
              <div className="flex flex-col items-center justify-center bg-white rounded-lg min-h-[220px] shadow-lg w-full">
                <div className="spinner"></div>
              </div>
            ) : (
              knowledgeBases?.map((knowledgeBaseData) => (
                <KnowledgeBaseCard
                  key={knowledgeBaseData.id}
                  knowledgeBaseData={knowledgeBaseData}
                  hideDropdown={hideDropdown}
                  setDeletingId={setDeletingId}
                  setOpenModal={setOpenModal}
                />
              ))
            )}
          </div>
        </div>
      </div>
      {showEditModal && currentTemplate && (
        <EditKnowledgeBase kb={currentTemplate} closeModal={closeEditModal} />
      )}
      <Modal show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Body className="flex flex-col justify-center items-center space-y-4">
          <div className="text-center font-bold">Warning!</div>
          <div className="text-center">
            Deleting this Knowledge Base is permanent. Are you sure you want to
            delete this Knowledge Base?
          </div>
          <div className="flex space-x-3">
            <Button outline onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
            <Button onClick={confirmDelete}>Delete</Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default KnowledgeBase;
