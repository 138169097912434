import { random } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { processImage, enhanceImage } from '../../services';
import PercentageLoader from '../common/loaders/PercentageLoader';

function Image(props) {
  const queryClient = useQueryClient();
  const prevPromptRef = useRef(props.campaign.post_image_prompt);
  const useHtmlRef = useRef(props.useHtml);
  const [isLoading, setIsLoading] = useState(false);
  const [isImageReady, setIsImageReady] = useState(false);

  const processImageMutation = useMutation({
    mutationFn: async ({ prompt, seed, steps = 100 }) => {
      setIsLoading(true);
      setIsImageReady(false);
      const imageObj = await processImage(
        props.campaign.id,
        prompt,
        seed,
        steps,
        useHtmlRef.current
      );
      queryClient.setQueryData([`campaign-${props.campaign.id}`], (old) => {
        return {...old, post_image: imageObj.post_image};
      });
      setIsImageReady(true);
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  const enhanceImageMutation = useMutation({
    mutationFn: async () => {
      setIsLoading(true);
      setIsImageReady(false);
      const imageObj = await enhanceImage(props.campaign.id);
      queryClient.setQueryData([`campaign-${props.campaign.id}`], (old) => {
        return {...old, post_image: imageObj.post_image};
      });
      setIsImageReady(true);
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (!props.campaign.post_image || props.campaign.post_image_prompt !== prevPromptRef.current) {
      processImageMutation.mutate({prompt: props.campaign.post_image_prompt});
    }
  }, [props.campaign.post_image_prompt]);

  useEffect(() => {
    useHtmlRef.current = props.useHtml;
  }, [props.useHtml]);

  return (
    <div>
      {isLoading ? (
        <PercentageLoader
          isLoading={isLoading}
          isImageReady={isImageReady}
          setIsLoading={setIsLoading}
        />
      ) : (
        <div className="relative flex flex-row justify-center">
          {props.disabled && (
            <svg
              onClick={() =>
                processImageMutation.mutate({
                  prompt: props.campaign.post_image_prompt,
                  seed: random(0, 10000),
                })
              }
              className="absolute right-1 top-1 w-6 h-6 p-1 text-white bg-main-blue rounded-full hover:cursor-pointer"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 18 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M16 1v5h-5M2 19v-5h5m10-4a8 8 0 0 1-14.947 3.97M1 10a8 8 0 0 1 14.947-3.97"
              />
            </svg>
          )}
          {props.disabled && (
            <svg
              onClick={() => enhanceImageMutation.mutate()}
              className="absolute right-1 top-8 w-6 h-6 p-1 text-white bg-main-blue rounded-full hover:cursor-pointer"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 22 21"
            >
              <path d="M12.356 5.435 1.938 16.384a.5.5 0 0 0 .018.707l2.9 2.757a.5.5 0 0 0 .706-.018L15.978 8.882l-3.622-3.447Zm7.681-.819a.5.5 0 0 0-.018-.706l-2.9-2.757a.5.5 0 0 0-.707.017l-2.68 2.817 3.622 3.446 2.683-2.817Zm-2.89 12.233-1 .025-.024-1a1 1 0 1 0-2 .05l.025 1-1 .024a1 1 0 0 0 .05 2l1-.025.025 1a1 1 0 1 0 2-.05l-.025-1 1-.024a1 1 0 1 0-.05-2h-.001ZM2.953 9.2l.025 1a1 1 0 1 0 2-.05l-.025-1 1-.025a1 1 0 1 0-.05-2l-1 .025-.025-1a1 1 0 0 0-2 .049l.025 1-1 .025a1 1 0 0 0 .05 2l1-.024Zm15.07 2.626-2 .05.05 1.999 2-.05-.05-1.999ZM11.752.978l-2 .05.05 2 2-.05-.05-2Zm-2.95 2.075-2 .05.05 1.999 2-.05-.05-2ZM5.753 1.127l-1.999.05.05 2 1.999-.05-.05-2Zm15.194 7.625-2 .05.05 2 2-.05-.05-2Zm.125 4.998-2 .05.05 2 2-.05-.05-2Z" />
            </svg>
          )}
          <img
            src={
              props.campaign.post_edit_image || props.campaign.post_image
            }
            alt={props.campaign.post_image_prompt}
          />
        </div>
      )}
    </div>
  );
}

export default Image;