class AIModelType {
  static OPEN_AI = "OPEN_AI";
  static GROQ_AI = "GROQ_AI";

  static toReadable(type) {
    switch (type) {
      case this.OPEN_AI:
        return 'OpenAI';
      case this.GROQ_AI:
        return 'GroqAI';
      default:
        return null;
    }
  }

  static getTypeFromString(typeString) {
    switch (typeString) {
      case "OPEN_AI":
        return this.OPEN_AI;
      case "GROQ_AI":
        return this.GROQ_AI;
      default:
        return null;
    }
  }
}

export default AIModelType;