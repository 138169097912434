import { useNavigate } from "react-router-dom";

import { formatDateString2 } from "../../utils/common";
import edit from "../../assets/edit_data_filter.svg";
import delete_data from "../../assets/delete_data_filter.svg";
import { truncateContent } from "../../utils/common";


function KnowledgeBaseCard({
  knowledgeBaseData,
  hideDropdown,
  setDeletingId,
  setOpenModal,
}) {
  const navigate = useNavigate();
  
  const kbNameTruncated = truncateContent(knowledgeBaseData.knowledge_base_name, 50).truncatedText;
  return (
    <div
      key={knowledgeBaseData.id}
      className="flex relative flex-col justify-between items-center bg-[#F1F0F0] rounded-md shadow-lg w-full max-w-[284px] min-h-[220px] p-4 cursor-pointer"
    >
      <div
        className="absolute top-3 left-3 bg-[#1565C0] text-white font-bold px-3 py-1 rounded-md text-xs md:text-sm"
        style={{ backgroundColor: "#1565C0" }}
      >
        {knowledgeBaseData.status}
      </div>
      <div
        title={knowledgeBaseData.knowledge_base_name}
        className="w-full text-lg md:text-xl text-center font-bold text-[#6B6B6B] my-4 px-2 break-words overflow-hidden text-ellipsis mt-7"
      >
        {kbNameTruncated}
      </div>
      <div className="w-full text-center text-xs md:text-sm font-medium text-[#6B6B6B] mb-8">
        Created on:{" "}
        <span className="font-bold">
          {formatDateString2(knowledgeBaseData.created_at) ?? "-"}
        </span>
      </div>
      <div className="flex space-x-4">
        <img
          src={edit}
          alt="Edit"
          className="cursor-pointer"
          onClick={() => {
            navigate(`/mkt/knowledgebase/edit`, {
              state: { kb: knowledgeBaseData },
            });
            hideDropdown();
          }}
        />
        <img
          src={delete_data}
          alt="Delete"
          className="cursor-pointer"
          onClick={() => {
            setDeletingId(knowledgeBaseData.id);
            setOpenModal(true);
            hideDropdown();
          }}
        />
      </div>
    </div>
  );
};

export default KnowledgeBaseCard;
