import { fetchAuthSession } from "aws-amplify/auth";
import { API_SERVER } from "../config/constants";

export const schedulePost = async (post) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/schedulePost`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
      body: JSON.stringify(post),
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.message || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to schedulePost:", error);
    throw error;
  }
};

export const generateTemplateContent = async (caption) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/template/content`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
      body: JSON.stringify({
        post: caption,

      }),
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to generateCaption:", error);
    throw error;
  }
};

export const getUploadPresignedUrls = async (fileNames) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/presigned-urls`,
      {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify({
          file_names: fileNames
        }),
      }
    );
  
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to getUploadPresignedUrls:", error);
    throw error;
  }
};

export const uploadFileToS3 = async (file, presignedUrl) => {
  try {
    const response = await fetch(presignedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
      },
    });

    return true;
  } catch (error) {
    console.error("Failed to uploadFileToS3:", error);
    throw error;
  }
};

export const fetchApps = async () => {
  const session = await fetchAuthSession();
  const response = await fetch(API_SERVER + "/apps", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${session.tokens.idToken.toString()}`,
    },
  });

  const responseData = await response.json();
  if (!response.ok) throw new Error(responseData.message || "Network response was not ok");
  return responseData.filter(app => app.active);

};