import React, { useState } from "react";
import classNames from "classnames";

const ActionButtonsGroup = ({
  onCancel,
  onSave,
  cancelLabel = "Cancel",
  saveLabel = "Save Changes",
  saveDisabled = false,
  buttonSize = "medium",
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    if (saveDisabled || isSaving) return;

    setIsSaving(true);
    try {
      await onSave();
    } finally {
      setIsSaving(false);
    }
  };

  const sizeClasses = {
    small: "min-h-[10px] min-w-[80px] px-2 py-2 text-sm rounded-[28px]",
    medium: "min-h-[40px] min-w-[180px] px-4 py-3 text-base rounded-[42px]",
    large: "min-h-[48px] min-w-[240px] px-5 py-4 text-lg rounded-[56px]",
  };

  const saveBtnClass = classNames(
    "text-white flex items-center justify-center",
    sizeClasses[buttonSize],
    {
      "hover:bg-main-blue hover:bg-gradient-to-b hover:from-blue-300 hover:to-main-blue":
        !saveDisabled && !isSaving,
      "cursor-not-allowed bg-main-gray": saveDisabled || isSaving,
      "bg-main-blue": !saveDisabled && !isSaving,
      "cursor-pointer": !saveDisabled && !isSaving,
    }
  );

  const cancelBtnClass = classNames(
    "cursor-pointer flex items-center justify-center bg-white border-2 border-blue-400 text-[#1DA1F2] border-solid",
    sizeClasses[buttonSize]
  );

  return (
    <div className="flex flex-col px-5 justify-center mt-2 w-full text-xl tracking-wide leading-none text-neutral-900 max-md:max-w-full">
      <div className="flex bottom-0 gap-5 justify-center items-center  text-base font-semibold tracking-tight leading-none text-center bg-white">
        {onCancel && (
          <button onClick={onCancel} className={cancelBtnClass}>
            <div className="bg-clip-text bg-[linear-gradient(180deg,#5EC2FF_0%,#1DA1F2_100%)]">
              {cancelLabel}
            </div>
          </button>
        )}
        <button
          onClick={handleSave}
          className={saveBtnClass}
          disabled={saveDisabled || isSaving}
        >
          {isSaving ? (
            <div className="flex items-center space-x-2">
              <span className="loader border-t-2 border-white rounded-full w-5 h-5 animate-spin"></span>
              <span>Loading...</span>
            </div>
          ) : (
            saveLabel
          )}
        </button>
      </div>
    </div>
  );
};

export default ActionButtonsGroup;
