class FilterType {
    static DATA_FILTERS = "DATA_FILTERS";
    static CUSTOM_FILES = "CUSTOM_FILES";
    static NO_SELECTED = "NO_SELECTED";
  
    static toReadable(type) {
      switch (type) {
        case this.DATA_FILTERS:
          return 'Data filters';
        case this.CUSTOM_FILES:
          return "Custom Files";
        case this.NO_SELECTED:
          return "Select Filter Type";
        default:
          return null;
      }
    }
  }
  
  export default FilterType;