
export const validateInput = (input, field, setErrors, errorMessage) => {
    if (!input.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: errorMessage || `${field.charAt(0).toUpperCase() + field.slice(1)} is required.`,
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: '' }));
    }
};

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}
