import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import ArxivDateRangePicker from "./ArxivDateRangePicker";
import Dropdown from "../common/forms/Dropdown";
import DataSourceType from "../../data/enums/data_source_type_enum";
import FileUploader from "./FileUploader";
import { validateInput } from "../../utils/validations";


const DataFilterForm = ({
    item,
    handleSelect,
    readOnly = false,
    onChangeName,
    setStartDate,
    setEndDate,
    setKeywords,
    setAuthors,
    selectedFiles,
    handleFilesChange,
    currentDataFilters = [],
    fromEdit = false,
}) => {

    const [errors, setErrors] = useState({
        name: '',
        source: '',
        keywords: ''
    });

    useEffect(() => {
        if (!readOnly) {
            setErrors({
                name: 'Data filter name is required',
                source: 'Select a source for the data filter.',
                keywords: 'At least one keyword is required'
            });
        }
    }, [readOnly]);

    const handleValidateSource = (source) => {
        validateInput(source, 'source', setErrors, 'Select a source for the data filter.');
        if (source.trim()) {
            handleSelect(DataSourceType.toBackendType(source));
        }
    };

    let dataSourceOptions = [DataSourceType.X, DataSourceType.ARXIV, DataSourceType.CUSTOM_FILES];
    const existCustomDataFilter = currentDataFilters.some(df => DataSourceType.CUSTOM_FILES === DataSourceType.getTypeFromString(df.source));

    return (
        <div className="p-4 bg-white flex flex-col justify-center w-full text-base tracking-wide leading-none text-neutral-900 space-y-6">
            <div className="flex flex-col space-y-4">
                <div className="flex-1 shrink self-stretch px-5 py-4 w-full bg-white rounded-lg border border-solid border-slate-300 min-h-[60px]">
                    <input
                        type="text"
                        placeholder="Data Filter Name"
                        value={item.name}
                        readOnly={readOnly}
                        onChange={(e) => onChangeName && onChangeName(e.target.value)}
                        className="w-full bg-transparent border-none text-neutral-900 text-base tracking-wide leading-none outline-none py-2"
                        onBlur={() => validateInput(item.name, 'name', setErrors, 'Data filter name is required')}
                    />
                </div>

                {!readOnly && errors.name && <div className="text-red-500 text-xs mt-1">{errors.name}</div>}

                <Dropdown
                    selectedOption={item.source}
                    options={dataSourceOptions}
                    onSelect={handleValidateSource}
                    labelExtractor={(option) => DataSourceType.toReadable(DataSourceType.getTypeFromString(option))}
                    disabledExtractor={(option) => {
                        const isCustomFilesOption = DataSourceType.getTypeFromString(option) === DataSourceType.CUSTOM_FILES;
                        return isCustomFilesOption && (existCustomDataFilter || fromEdit);
                    }}
                    readOnly={readOnly}
                />
                {!readOnly && errors.source && <div className="text-red-500 text-xs mt-1">{errors.source}</div>}
                {DataSourceType.getTypeFromString(item.source) === DataSourceType.ARXIV && (
                    <ArxivDateRangePicker
                        startDate={item.start_date}
                        endDate={item.end_date}
                        readOnly={readOnly}
                        setStartDate={readOnly ? null : setStartDate}
                        setEndDate={readOnly ? null : setEndDate}
                    />
                )}
                {DataSourceType.getTypeFromString(item.source) === DataSourceType.CUSTOM_FILES && (
                    <FileUploader
                        maxFiles={10}
                        maxFileSizeMB={10}
                        initialFiles={selectedFiles}
                        onFilesChange={handleFilesChange}
                        readonly={readOnly}
                    />
                )}
                {DataSourceType.getTypeFromString(item.source) !== DataSourceType.CUSTOM_FILES && (<>
                    <div className="flex flex-col w-full">
                        <div className="flex-1 shrink self-stretch px-5 py-4 w-full text-base tracking-wide leading-none bg-white rounded-lg border border-solid border-slate-300 min-h-[60px] text-neutral-900">
                            <input
                                type="text"
                                placeholder="Filter by Keywords"
                                value={item.keywords}
                                readOnly={readOnly}
                                onChange={(e) => !readOnly && setKeywords(e.target.value)}
                                className="w-full bg-transparent border-none text-neutral-900 text-base tracking-wide leading-none outline-none py-2"
                                onBlur={() => validateInput(item.keywords, 'keywords', setErrors, 'At least a keyword is required.')}
                            />

                        </div>
                        {!readOnly && errors.keywords &&
                            <div className="text-red-500 text-xs mt-1">{errors.keywords}</div>
                        }
                        <div className="mt-3 text-sm tracking-wide leading-none text-gray-700">
                            To add multiple keywords, separate them with commas
                        </div>
                    </div>
                    <div className="flex flex-col w-full">
                        <div className="flex-1 shrink self-stretch px-5 py-4 w-full text-base tracking-wide leading-none bg-white rounded-lg border border-solid border-slate-300 min-h-[60px] text-neutral-900">
                            <input
                                type="text"
                                placeholder="Filter by Authors"
                                value={item.authors}
                                readOnly={readOnly}
                                onChange={(e) => !readOnly && setAuthors(e.target.value)}
                                className="w-full bg-transparent border-none text-neutral-900 text-base tracking-wide leading-none outline-none py-2"
                            />
                        </div>
                        <div className="mt-3 text-sm tracking-wide leading-none text-gray-700">
                            To add multiple authors, separate them with commas
                        </div>
                    </div>
                </>)}
            </div>
        </div>
    );
};

export default DataFilterForm;
