import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DEFAULT_AUTO_CLOSE_VALUE = 3000;

const notify = (message, options = {}) => {
  toast(message, options);
};

const notifySuccess = (message, options = {}) => {
  toast.success(message, { ...options, autoClose: DEFAULT_AUTO_CLOSE_VALUE });
};

const notifyError = (message, options = {}) => {
  toast.error(message, { ...options, autoClose: DEFAULT_AUTO_CLOSE_VALUE });
};

const notifyInfo = (message, options = {}) => {
  toast.info(message, { ...options, autoClose: DEFAULT_AUTO_CLOSE_VALUE });
};

const notifyWarning = (message, options = {}) => {
  toast.warn(message, { ...options, autoClose: DEFAULT_AUTO_CLOSE_VALUE });
};

// should be used just one time in the main file
const renderToastContainer = (options = {}) => (
    <ToastContainer
      {...options}
    />
  );

export { renderToastContainer, notify, notifySuccess, notifyError, notifyInfo, notifyWarning };