import React, { useState } from "react";
import {
  FaChevronDown,
  FaChevronUp,
  FaPencilAlt,
  FaTrash,
} from "react-icons/fa";
import { formatDateString } from "../../utils/common";
import { stateMap } from "../../data/CampaignStates";
import { useNavigate } from "react-router-dom";

function CampaignsTable({ campaigns, deleteCampaignHandler }) {
  const navigate = useNavigate();
  const [expandedRow, setExpandedRow] = useState(null);

  const toggleExpandRow = (id) => {
    setExpandedRow(expandedRow === id ? null : id);
  };

  const listCampaigns = (campaignList = campaigns, isSubCampaign = false) => {
    return campaignList.map((c, i) => {
      const isMultiCampaign = c.type === "MULTI" && c.multi_id === "";

      const views = c.metrics?.social_network === 'X' ? c.metrics?.metrics?.impression_count : '-';
      const reactions = c.metrics?.social_network === 'X' ? c.metrics?.metrics?.like_count : '-';
      const comments = c.metrics?.social_network === 'X' ? c.metrics?.metrics?.reply_count : '-';

      return (
        <React.Fragment key={c.id || i}>
          <tr
            className={`hover:bg-light-blue hover:cursor-pointer border-b ${
              isSubCampaign ? "bg-gray-200" : ""
            }`}
          >
            <td
              onClick={() => navigate(`/mkt/campaigns/${c.id}/assistant`)}
              className={`py-3 px-4 ${isSubCampaign ? "pl-10" : ""} w-60`}
            >
              <div className="h-20 flex-col justify-center items-start gap-1 inline-flex">
                <div className="self-stretch text-black text-lg font-semibold leading-normal tracking-tight">
                  {isSubCampaign ? `${i + 1}. ${c.title}` : c.title}
                </div>
                <div className="self-stretch text-light-gray text-base font-normal leading-snug tracking-tight">
                  {formatDateString(c.created_at)}
                </div>
              </div>
            </td>
            <td className="py-3 px-4 w-32">
              <div
                className={
                  "h-6 px-3 rounded justify-start items-center gap-1.5 inline-flex " +
                  stateMap[c.state].classNameOuter
                }
              >
                <div
                  className={
                    "text-center text-sm font-normal leading-tight " +
                    stateMap[c.state].classNameInner
                  }
                >
                  {stateMap[c.state].title}
                </div>
              </div>
            </td>
            <td className="py-3 px-4 w-10">
              <span className="text-light-gray text-base font-semibold leading-snug tracking-tight">
                {views}
              </span>
            </td>
            <td className="py-3 px-4 w-10">
              <span className="text-light-gray text-base font-semibold leading-snug tracking-tight">
                {reactions}
              </span>
            </td>
            <td className="py-3 px-4 w-10">
              <span className="text-light-gray text-base font-semibold leading-snug tracking-tight">
                {comments}
              </span>
            </td>
            <td className="py-8 px-5 w-10">
              <div className="flex justify-start items-center space-x-7">
                <div
                  onClick={() => navigate(`/mkt/campaigns/${c.id}/assistant`)}
                  className="cursor-pointer"
                >
                  <FaPencilAlt
                    size={20}
                    className="text-gray-600 hover:text-black"
                  />
                </div>

                <div
                  onClick={() => deleteCampaignHandler(c.id)}
                  className="cursor-pointer"
                >
                  <FaTrash
                    size={20}
                    className="text-gray-600 hover:text-red-600"
                  />
                </div>
              </div>
            </td>
            <td className="py-8 px-5 w-20">
              {isMultiCampaign ? (
                <div
                  onClick={() => {
                    if (c.sub_campaigns && c.sub_campaigns.length > 0) {
                      toggleExpandRow(c.id);
                    }
                  }}
                  className={`cursor-pointer ${
                    !c.sub_campaigns || c.sub_campaigns.length === 0 ? 'cursor-not-allowed opacity-50' : ''
                  }`}
                >
                  {expandedRow === c.id ? (
                    <FaChevronUp size={20} />
                  ) : (
                    <FaChevronDown size={20} />
                  )}
                </div>
              ) : (
                <div className="invisible">
                  <FaChevronDown size={20} />
                </div>
              )}
            </td>
          </tr>
          {expandedRow === c.id && c.sub_campaigns && (
            <React.Fragment key={`sub-${c.id}`}>
              {listCampaigns(c.sub_campaigns, true)}
            </React.Fragment>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <table className="max-md:table-fixed overflow-auto w-full">
      <thead className="bg-yet-another-gray text-light-gray font-semibold m-4">
        <tr>
          <td className="py-3 px-4 w-60">Title</td>
          <td className="py-3 px-4 w-32">Status</td>
          <td className="py-3 px-4 w-10">Views</td>
          <td className="py-3 px-4 w-10">Reactions</td>
          <td className="py-3 px-4 w-10">Comments</td>
          <td className="py-3 px-4 w-10">Actions</td>
          <td className="py-3 px-4 w-20"></td>
        </tr>
      </thead>
      <tbody>{listCampaigns()}</tbody>
    </table>
  );
}

export default CampaignsTable;